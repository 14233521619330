import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import clsx from "clsx";

import { useTranslate } from "../../../../../../../context/translate/translate.context";
import CheckMarkNewClosed from "../../../../../../svg/Checkmark/CheckMarkNewClosed";
import CheckMarkNewOpen from "../../../../../../svg/Checkmark/CheckMarkNewOpen";
import { TranslateText } from "../../../../../../translate-text";
import styles from "./styles.module.css";

const TopUpBalanceStep3 = ({
  loading,
  selectedPayment,
  onPaymentSelect,
  next,
  close,
}) => {
  const { translate } = useTranslate();

  const paymentMethods = [
    // { _id: "yookassa", name: "yookassa" },
    // { _id: "paybox", name: "paybox" },
    // { _id: "transfer", name: "transfer" },
    // { _id: "lava", name: "lava" },
    // { _id: "stripe", name: "stripe" },
    { _id: "wata", name: "wata" },
   //  { _id: "yooMoney", name: "yooMoney" },
  ];

  return (
    <>
      <Box className={styles.selectPaymentContainer}>
        {paymentMethods.map((payment) => (
          <FormControlLabel
            sx={{ color: "#f6f6f6" }}
            control={
              <Checkbox
                key={payment._id}
                checked={selectedPayment?._id === payment._id}
                onChange={() => onPaymentSelect(payment)}
                icon={<CheckMarkNewClosed />}
                checkedIcon={<CheckMarkNewOpen />}
              />
            }
            label={translate(
              `promoteProductModal.modal2.paymentMethodInput.${payment.name}`
            )}
          />
        ))}
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          className={clsx(styles.button, styles.cancelButton)}
          onClick={close}
        >
          <TranslateText value="vendorDashboard.topUp.steps.step3.cancel" />
        </Button>

        <LoadingButton
          loading={loading}
          className={clsx(styles.button, styles.submitButton)}
          onClick={next}
        >
          <TranslateText value="vendorDashboard.topUp.steps.step3.submit" />
        </LoadingButton>
      </Box>
    </>
  );
};

export default TopUpBalanceStep3;

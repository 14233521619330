import { Box, Button, Container, Typography, useTheme } from "@mui/material";

import { useTranslate } from "../../context/translate/translate.context";
import Footer from "../Layout/Footer";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const user = JSON.parse(localStorage.getItem("user"))
  const [data, setData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("refreshToken");
        let url = '';
        if (process.env.NODE_ENV.includes('production')) {
          url = process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL + '/order/purchase/latest?userId='+user._id
            : '';
        } else {
          url = 'http://localhost:3030/api/order/purchase/latest?userId='+user._id;
        }
        const data = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })

        if(data?.data?.orderId){
          navigate('/orderb/'+data?.data?.orderId)
        }
        if(data?.data?.transaction){
          navigate('/profile/'+user.userSlug+'#wallet?id='+data?.data?.transaction._id)
        }
      } catch (e) {
        console.log(e)
        navigate('/profile/'+user.userSlug+'#purchases')
      }
    }

    if(user?._id && data === null) {
      fetchData()
    } else {
      navigate('/')
    }
  }, [user])

  return (
    <Box className={styles.wrapper}>
      <Container className={styles.container}>
        <Typography className={styles.heading} variant="h1" color="main">
          {translate("paymentSuccess.succeeded")}
        </Typography>
        <Typography className={styles.text}>
          {`Вы будете перенаправлены на страницу заказа через 5 секунд.`}
        </Typography>
        {/*
        <Button
          className={styles.button}
          variant="contained"
          onClick={() => navigate("/catalog")}
          >
           
          {translate("paymentSuccess.goCatalog")}
        </Button> */}
      </Container>
      <Footer customFooter />
    </Box>
  );
}

export default PaymentSuccess;

import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  SliderThumb,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { CURRENCY_SIGN_BY_COUNTRY } from "../../../constants";
import { useTranslate } from "../../../context/translate/translate.context";
import { priceDiscount } from "../../../lib/priceHelper";
import {
  decreaseQuantity,
  increaseQuantity,
  removeItems,
  resetCart,
} from "../../../store/cartStore";
import { priceLabel } from "../../../utils/strings/price";
import AlertComponent from "../../AlertComponent";
import BreadcrumbsNavigation from "../../BreadcrumbsNavigation";
import MentupStepper from "../../MentupStepper";
import PaymentInformation from "../../PaymentInformation";
import ReviewsStars from "../../Reviews";
import TelegramPayment from "../../TelegramComponents/Payment/TelegramPayment";
import Button from "../../UI/Button";
import ItemCounter from "../../cart/ItemCounter";
import CheckedIcon from "../../svg/Checked";
import Delete from "../../svg/Delete";
import UncheckedIcon from "../../svg/Unchecked";
import CartPageRecommended from "../CartPageRecommended";
import PromocodeBlock from "./PromocodeBlock";
import styles from "./styles.module.css";

const CartPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const cartItems = useSelector((state) => state.cart);
  const { country } = useSelector((state) => state.user);

  const [alert, setAlert] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [telegramPaymentData, setTelegramPaymentData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [currentBalanceAmountToPay, setCurrentBalanceAmountToPay] = useState(0);

  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [step, setStep] = useState(0);

  const [total, setTotal] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));

  const paymentWithBalance = currentBalanceAmountToPay >= total;

  const calculateTotal = () => {
    let total = 0;

    for (const { product, quantity } of cartItems) {
      if (!product.promocode) {
        total = total + quantity * product.price;
      } else if (
        product.promocode &&
        (product.promocode.discount || product.promocode.discount === 0)
      ) {
        total =
          total +
          quantity * priceDiscount(product.price, product.promocode.discount);
      }
    }

    setTotal(total);
  };

  const handleSliderChange = (value) => {
    const newAmount =
      currentBalanceAmountToPay <= parseFloat(user?.wallet?.balance)
        ? value
        : parseFloat(user?.wallet?.balance);

    setCurrentBalanceAmountToPay(newAmount);
  };

  useEffect(() => {
    setStep(0);
    setPaymentMethod("");
    setDiscountedTotal(total - currentBalanceAmountToPay);
  }, [currentBalanceAmountToPay]);

  useEffect(() => {
    calculateTotal();
  }, [cartItems]);

  useEffect(() => {
    if (total < 1) {
      setPaymentMethod("");
    }

    /*
    if (total && !initialSliderPriceSet) {
      handleSliderChange(total);
      setInitialSliderPriceSet(true);
    } */
  }, [total]);

  const calculateGrandTotal = () => {
    if (currentBalanceAmountToPay > 0 && currentBalanceAmountToPay <= total) {
      return (total - (total - discountedTotal))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }

    return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const getReferralData = () => {
    const referral = localStorage.getItem("referral");

    if (!referral) {
      return {};
    }

    return JSON.parse(referral);
  };

  const getInviteData = () => {
    const invite = localStorage.getItem("hasInvite");

    if (!invite) {
      return {};
    }
    return JSON.parse(invite);
  };

  const handlePaymentMethod = async (method) => {
    switch (method) {
      case "balance":
        const payUrl = process.env.NODE_ENV.includes("production")
          ? process.env.REACT_APP_SERVER_URL
            ? `${process.env.REACT_APP_SERVER_URL}/payment/success`
            : ""
          : "http://localhost:3030/api/payment/success";

        const cartItemsFormatted = cartItems.map((cartItem) => {
          // Create a new cartItem with updated user data
          return {
            ...cartItem,
            product: {
              ...cartItem.product,
              user: {
                _id: cartItem.product.user._id,
                userSlug: cartItem.product.user.userSlug,
                avatar: cartItem.product.user.avatar,
                username: cartItem.product.user.username
              }
            }
          };
        });
        const body = {
          userId: user._id,
          userEmail: user.email,
          cart: cartItemsFormatted,
          type: "balance",
          currency: "RUB",
          country: "RU",
          total: calculateGrandTotal(),
          discountCode: discountCode,
          balanceUsed: currentBalanceAmountToPay,
          ...getReferralData(),
          ...getInviteData()
        };

        try {
          const res = await axios.post(payUrl, body, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("refreshToken")}`,
            },
          });

          if (currentBalanceAmountToPay > 0) {
            const user = JSON.parse(localStorage.getItem("user"));

            const newUser = {
              ...user,
              wallet: {
                ...user.wallet,
                balance:
                  parseFloat(user.wallet.balance) -
                  parseFloat(currentBalanceAmountToPay),
              },
            };

            localStorage.setItem("user", JSON.stringify(newUser));
            //dispatch(setUserUpdate(newUser));
          }

          dispatch(resetCart());
          localStorage.removeItem("cart");
          localStorage.removeItem("referral");
          localStorage.removeItem("hasInvite");

          const orderId = res.data
          navigate(`/orderb/${orderId}?from=paidBalance`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } catch (error) {
          setAlert({
            message:
              error.response?.data?.message ||
              translate("error404.somethingWentWrong"),
            severity: "error",
          });
          //console.error(error);
        }

        return;

      default:
        break;
    }
  };

  const handleApplyDiscount = async (discountCode) => {
    try {
      const payUrl = process.env.NODE_ENV.includes("production")
        ? process.env.REACT_APP_SERVER_URL + "/promo/discount/"
        : "http://localhost:3030/api/promo/discount/";

        const cartItemsFormatted = cartItems.map((cartItem) => {
          // Create a new cartItem with updated user data
          return {
            ...cartItem,
            product: {
              ...cartItem.product,
              user: {
                _id: cartItem.product.user._id,
                userSlug: cartItem.product.user.userSlug,
                avatar: cartItem.product.user.avatar,
                username: cartItem.product.user.username
              }
            }
          };
        });

      const body = {
        cart: cartItemsFormatted,
        discountCode,
        country,
      };

      const {
        data: { status, total: responseTotal, discount },
      } = await axios.post(payUrl, body);

      if (status === "success") {
        setDiscountCode(discountCode);

        setTotal(parseFloat(responseTotal));
        setDiscount(parseFloat(discount));
        setAlert({
          message: translate("cartPage.promocodeAppliedMessage"),
          severity: "success",
        });
        setPaymentMethod("");
        setStep(0);
      } else {
        setDiscountCode("");
        setDiscount(0);
      }
    } catch (err) {
      //console.error(err);
      setAlert({
        message: err.response.data.message,
        severity: "error",
      });
      calculateTotal();
      setDiscount(0);
      setDiscountCode("");
      setStep(0);
      setPaymentMethod("");
    } finally {
      setCurrentBalanceAmountToPay(0);
    }
  };

  const handlePaymentReady = async () => {
    setLoading(true);
    const token = Cookies.get("refreshToken");

    if (!token) {
      return navigate("/login?from=cart");
    }

    try {
      if (paymentWithBalance) {
        return await handlePaymentMethod("balance");
      }

      const payUrl = process.env.NODE_ENV.includes("production")
        ? process.env.REACT_APP_SERVER_URL
          ? `${process.env.REACT_APP_SERVER_URL}/payment/checkReady`
          : ""
        : "http://localhost:3030/api/payment/checkReady";

      const cartItemsFormatted = cartItems.map((cartItem) => {
        // Create a new cartItem with updated user data
        return {
          ...cartItem,
          product: {
            ...cartItem.product,
            user: {
              _id: cartItem.product.user._id,
              userSlug: cartItem.product.user.userSlug,
              avatar: cartItem.product.user.avatar,
              username: cartItem.product.user.username
            }
          }
        };
      });
      const body = {
        cart: cartItemsFormatted,
        country,
      };

      await axios.post(payUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setStep(1);
    } catch (err) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChoosePaymentMethod = (method) => {
    switch (method) {
      // TODO: Add additional logics for payment methods in case if needed

      default:
        return setPaymentMethod(method);
    }
  };

  const handlePayTransfer = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/transfer`;

    const body = {
      cart: cartItems,
      userId: user._id,
      country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");

      if (response.data?.paymentCode) {
        navigate(`/payment/transfer/${response.data.paymentCode}`);
      }
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayLava = async () => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/lava`;

    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayTinkoff = async () => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/wata`;

    const user = JSON.parse(localStorage.getItem("user"));

    const cartItemsFormatted = cartItems.map((cartItem) => {
      // Create a new cartItem with updated user data
      return {
        ...cartItem,
        product: {
          ...cartItem.product,
          user: {
            _id: cartItem.product.user._id,
            userSlug: cartItem.product.user.userSlug,
            avatar: cartItem.product.user.avatar,
            username: cartItem.product.user.username
          }
        }
      };
    });
    const body = {
      cart: cartItemsFormatted,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayYooMoney = async () => {
    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/yooMoney`;

    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayStripe = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };
    setTelegramPaymentData(body);
    setShowTelegramModal(true);
  };

  const handlePayment = () => {
    setLoading(true);
    switch (paymentMethod) {
      case "transfer":
        return handlePayTransfer();

      case "lava":
        return handlePayLava();

      case "stripe":
        return handlePayStripe();

      case "wata":
        return handlePayTinkoff();
      
      case "yooMoney":
        return handlePayYooMoney();

      default:
        setAlert({
          message: translate("cartPage.payment.labels.paymentMethodNotChosen"),
          severity: "error",
        });
        setLoading(false);
        return;
    }
  };

  const handleRedirect = (href) => () => {
    if (href) {
      navigate(href);
    }
  };

  const handleCheck = (id) => {
    setCheckedItems(
      checkedItems.includes(id)
        ? checkedItems.filter((item) => item !== id)
        : [...checkedItems, id]
    );
  };

  const handleRemove = () => {
    dispatch(removeItems(checkedItems));
    setCheckedItems([]);
  };

  const currencySign = CURRENCY_SIGN_BY_COUNTRY[country] || "₽";

  return (
    <Container>
      <AlertComponent
        message={alert?.message}
        severity={alert?.severity || "error"}
        resetMessageInParent={() => setAlert(null)}
      />
      <BreadcrumbsNavigation
        breadcrumbs={[
          { label: "navigation.home", path: "/" },
          { label: "navigation.cart" },
        ]}
      />
      <CartPageRecommended />
      <Typography className={styles.title} variant="h2">
        {translate("cartPage.title")}
      </Typography>
      {cartItems.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8.5}>
            <Box className={styles.control}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.checkbox}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    checked={checkedItems.length === cartItems.length}
                    onChange={() =>
                      setCheckedItems(
                        checkedItems.length === cartItems.length
                          ? []
                          : cartItems.map(({ product }) => product._id)
                      )
                    }
                  />
                }
                label={translate("cartPage.selectAll")}
              />
              {isMobile ? (
                <IconButton
                  className={styles.removeSelectedButton}
                  onClick={handleRemove}
                >
                  <Delete />
                </IconButton>
              ) : (
                <Button
                  className={styles.removeSelectedButton}
                  startIcon={<Delete />}
                  onClick={handleRemove}
                >
                  {translate("cartPage.removeSelected")}
                </Button>
              )}
            </Box>

            {cartItems.map(({ product, quantity }) => {
              const {
                name,
                media,
                user,
                category,
                promocode,
                price,
                currency,
                soldOut,
                slug,
                type,
              } = product;

              return (
                <Box key={name} className={styles.productContainerWrapper}>
                  <Box
                    className={clsx(
                      styles.productContainer,
                      soldOut && styles.productContainer_soldOut
                    )}
                  >
                    <Box className={styles.productContainer__checkboxContainer}>
                      <Checkbox
                        className={styles.checkbox}
                        icon={<UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        checked={checkedItems.includes(product._id)}
                        onChange={() => handleCheck(product._id)}
                      />
                    </Box>

                    {isMobile ? (
                      <Box
                        className={styles.productContainer__productInfoWrapper}
                      >
                        <Box className={styles.productContainer__productInfo}>
                          <Box
                            className={styles.productContainer__image}
                            component="img"
                            onClick={handleRedirect(
                              slug &&
                                user.userSlug &&
                                `/profile/${user.userSlug}/${slug}`
                            )}
                            src={
                              media?.[0] || "/images/product_placeholder.png"
                            }
                            alt={`${name} image`}
                          />
                          <Box sx={{ maxWidth: "192px" }}>
                            <Typography
                              noWrap
                              className={styles.productContainer__title}
                              onClick={handleRedirect(
                                slug &&
                                  user.userSlug &&
                                  `/profile/${user.userSlug}/${slug}`
                              )}
                            >
                              {name}
                            </Typography>
                            <Box
                              className={
                                styles.productContainer__chipsContainer
                              }
                            >
                              <Chip
                                className={styles.productContainer__chip}
                                key={`${type.slug}`}
                                label={type.name}
                                onClick={handleRedirect(
                                  `/games/${category.slug}/${type.slug}`
                                )}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.productContainer__dataContainer}>
                          <Typography
                            className={styles.productContainer__owner}
                            onClick={handleRedirect(
                              user.userSlug && `/profile/${user.userSlug}`
                            )}
                            aria-label="Go to user profile"
                            component={Link}
                          >
                            <Avatar
                              className={styles.productContainer__avatar}
                              alt={`${user.username} avatar`}
                              src={user.avatar}
                            />
                            <Box sx={{ maxWidth: "72px" }}>
                              <Typography noWrap>{user.username}</Typography>
                              <ReviewsStars
                                size="small"
                                reviews={user.reviews}
                                starsOnly
                              />
                            </Box>
                          </Typography>
                          <Typography
                            className={styles.productContainer__price}
                            sx={{ flexDirection: promocode ? "column" : "row" }}
                          >
                            {promocode ? (
                              <>
                                <Box
                                  className={
                                    styles.productContainer__price__strike
                                  }
                                  component="span"
                                >
                                  {priceLabel(price, { currency })}
                                </Box>
                                <Box component="span">
                                  {priceLabel(
                                    price -
                                      (price * promocode.discount) / 100.0,
                                    { currency }
                                  )}
                                </Box>
                              </>
                            ) : (
                              priceLabel(price, { currency })
                            )}
                          </Typography>
                          <ItemCounter
                            quantity={quantity}
                            increment={() =>
                              dispatch(increaseQuantity(product))
                            }
                            decrement={() =>
                              dispatch(decreaseQuantity(product))
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box
                          className={styles.productContainer__image}
                          component="img"
                          onClick={handleRedirect(
                            slug &&
                              user.userSlug &&
                              `/profile/${user.userSlug}/${slug}`
                          )}
                          src={media?.[0] || "/images/product_placeholder.png"}
                          alt={`${name} image`}
                        />
                        <Box className={styles.productContainer__dataContainer}>
                          <Typography
                            className={styles.productContainer__owner}
                            onClick={handleRedirect(
                              user.userSlug && `/profile/${user.userSlug}`
                            )}
                            aria-label="Go to user profile"
                            component={Link}
                          >
                            <Avatar
                              className={styles.productContainer__avatar}
                              alt={`${user.username} avatar`}
                              src={user.avatar}
                            />
                            <Box>
                              <Typography>{user.username}</Typography>
                              <ReviewsStars
                                size="medium"
                                reviews={user.reviews}
                                starsOnly
                              />
                            </Box>
                          </Typography>
                          <Typography
                            className={styles.productContainer__title}
                            onClick={handleRedirect(
                              slug &&
                                user.userSlug &&
                                `/profile/${user.userSlug}/${slug}`
                            )}
                          >
                            {name}
                          </Typography>
                          <Box
                            className={styles.productContainer__chipsContainer}
                          >
                            <Chip
                              className={styles.productContainer__chip}
                              key={`${type.slug}`}
                              label={type.name}
                              onClick={handleRedirect(
                                `/games/${category.slug}/${type.slug}`
                              )}
                            />
                          </Box>
                        </Box>
                        <Typography className={styles.productContainer__price}>
                          {promocode ? (
                            <>
                              <Box
                                className={
                                  styles.productContainer__price__strike
                                }
                                component="span"
                              >
                                {priceLabel(price, { currency })}
                              </Box>
                              {priceLabel(
                                price - (price * promocode.discount) / 100.0,
                                { currency }
                              )}
                            </>
                          ) : (
                            priceLabel(price, { currency })
                          )}
                        </Typography>
                        <ItemCounter
                          quantity={quantity}
                          increment={() => dispatch(increaseQuantity(product))}
                          decrement={() => dispatch(decreaseQuantity(product))}
                        />
                      </>
                    )}
                  </Box>
                  {soldOut && (
                    <Typography
                      className={styles.productContainer__soldOutLabel}
                    >
                      {translate("cartPage.soldOut")}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Box component="form" className={styles.formContainer}>
              <PromocodeBlock submit={handleApplyDiscount} />
              <Box className={styles.priceSummary}>
                <Box sx={{ width: "100%" }}>
                  <MentupStepper
                    getStepTitle={(index) =>
                      `${translate("vendorDashboard.topUp.steps.stepper")} ${
                        index + 1
                      }`
                    }
                    current={step}
                    steps={2}
                  />
                </Box>
                <Typography className={styles.priceSummary__title}>
                  {translate(
                    step === 0
                      ? "cartPage.orderSummary.title"
                      : "cartPage.orderSummary.secondTitle"
                  )}
                </Typography>
                {step === 0 ? (
                  <>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridTemplateRows: user?.wallet?.balance
                          ? "auto auto"
                          : "auto",
                        gap: "16px",
                        "& p": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      {/*
                      <Typography>
                        {translate("cartPage.orderSummary.labels.subtotal")}
                      </Typography>
                      <Typography
                        sx={{ justifySelf: "flex-end", paddingLeft: "23px" }}
                      >
                        {total || total === 0
                          ? `${total
                              .toFixed(2)
                              .replace(
                                /\d(?=(\d{3})+\.)/g,
                                "$&,"
                              )} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                      */}
                      {user?.wallet?.balance && (
                        <>
                          <Box>
                            <Typography>
                              {translate(
                                "cartPage.orderSummary.labels.useMentupBalance"
                              )}
                            </Typography>
                            <Slider
                              className={styles.priceSummary__balanceSlider}
                              value={currentBalanceAmountToPay}
                              slots={{
                                thumb: ({ children, ...props }) => (
                                  <SliderThumb {...props}>
                                    {children}
                                    <Box
                                      className={
                                        styles.priceSummary__sliderThumbDot
                                      }
                                      component="span"
                                    />
                                  </SliderThumb>
                                ),
                              }}
                              min={0}
                              step={0.01}
                              max={
                                parseFloat(user?.wallet?.balance) > total
                                  ? total
                                  : parseFloat(user?.wallet?.balance)
                              }
                              onChange={(e, value) => {
                                e.preventDefault();

                                handleSliderChange(value);
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>

                          <Typography
                            className={styles.priceSummary__balancePrice}
                          >
                            {`${currentBalanceAmountToPay.toFixed(
                              2
                            )} ${currencySign}`}
                          </Typography>
                        </>
                      )}
                    </Box>
                    {/*
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.serviceFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(
                                /\d(?=(\d{3})+\.)/g,
                                "$&,"
                              )} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box>
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.paymentFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(
                                /\d(?=(\d{3})+\.)/g,
                                "$&,"
                              )} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box>
                    */}
                    {discount > 0 && (
                      <Box className={styles.priceSummary__priceContainer}>
                        <Typography>
                          {translate("cartPage.orderSummary.labels.discount")}
                        </Typography>
                        <Typography>
                          {total || total === 0
                            ? `-${(total * discount) / 100} ${currencySign}`
                            : `${0.0} ${currencySign}`}
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box>
                    <RadioGroup
                      className={styles.priceSummary__radioGroup}
                      name="paymentMethod"
                      defaultValue="top"
                      sx={{ textAlign: "center" }}
                    >
                      {/* <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="transfer"
                        control={<Radio />}
                        label="Transfer"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("transfer")}
                      />
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="lava"
                        control={<Radio />}
                        label="Lava"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("lava")}
                      /> */}
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="Telegram"
                        control={<Radio />}
                        label="Telegram"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("stripe")}
                      /> */}
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="wata"
                        control={<Radio />}
                        label={translate(
                          "cartPage.orderSummary.labels.tinkoff"
                        )}
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("wata")}
                      />
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="yooMoney"
                        control={<Radio />}
                        label={translate(
                          "cartPage.orderSummary.labels.yooMoney"
                        )}
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("yooMoney")}
                      /> */}
                    </RadioGroup>
                  </Box>
                )}
                <Divider />
                <Box className={styles.priceSummary__priceContainer}>
                  <Typography className={styles.priceSummary__total}>
                    {translate("cartPage.orderSummary.labels.total")}
                  </Typography>
                  <Typography className={styles.priceSummary__totalPrice}>
                    {total
                      ? `${calculateGrandTotal()} ${currencySign}`
                      : `${0.0} ${currencySign}`}
                  </Typography>
                </Box>
              </Box>
              {step === 0 ? (
                <>
                  <Button onClick={handlePaymentReady} loading={loading}>
                    {translate(
                      paymentWithBalance
                        ? "cartPage.payment.labels.payWithBalanceButton"
                        : "cartPage.payment.labels.proceedToChoosingPaymentMethod"
                    )}
                  </Button>
                </>
              ) : (
                <Button onClick={handlePayment} loading={loading}>
                  {translate("cartPage.payment.labels.proceedButton")}
                </Button>
              )}
              <Box className={styles.bottomPlaceholder} />
              <PaymentInformation />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography>{translate("cartPage.emptyMessage")}</Typography>
      )}

      <TelegramPayment
        clearCart={true}
        cart={telegramPaymentData}
        open={showTelegramModal}
        setAlert={setAlert}
        close={() => {
          setLoading(false);
          setShowTelegramModal(false);
        }}
      />
    </Container>
  );
};

export default CartPage;

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useSelector } from "react-redux";

import { emailVerify, registerComplete } from "../../../api/auth/register";
import { useTranslate } from "../../../context/translate/translate.context";
import Button from "../../UI/Button";
import styles from "./styles.module.css";

const Step2 = ({
  data,
  loading,
  setLoading,
  logIn,
  previous,
  setAlertMessage,
}) => {
  const { translate } = useTranslate();

  const [otp, setOtp] = useState("");
  const [emailSent, setEmailSent] = useState(true);

  const userRedux = useSelector((state) => state.user);

  useEffect(() => {
    if (emailSent) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [emailSent]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const response = await registerComplete({
        ...data,
        country: userRedux.country,
        otp,
      });

      logIn(response);
    } catch (error) {
      //console.error(error);
      setAlertMessage(
        error?.response?.data?.invalidOtp
          ? translate("errorMessages.invalidOtp")
          : error?.response?.data?.otpExpired
          ? translate("errorMessages.otpExpired")
          : translate("authentication.unexpectedErrorOccurred")
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={styles.form}>
      <Typography className={styles.form__emailSentMessage}>
        {translate("authentication.checkYourEmailInviteLink")}
      </Typography>

      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        inputType="number"
        inputStyle={{
          background: "#f6f6f60d",
          border: "1px solid #f6f6f680",
          borderRadius: "3px",
          color: "#f6f6f6",
          fontSize: "24px",
          height: "38px",
          padding: "8px 12px",
          textAlign: "center",
          transition: "all 200ms cubic-bezier(0, 0, 0.2, 1)",
          width: "22px",
        }}
        shouldAutoFocus={true}
        skipDefaultStyles
        renderSeparator={<div style={{ width: "8px" }} />}
        renderInput={(props) => <input {...props} />}
      />

      <Button
        size="large"
        fullWidth
        loading={loading}
        style={{ marginTop: "16px" }}
        onClick={() => onSubmit()}
      >
        {translate("authentication.signInToYourAccount")}
      </Button>
      <Button
        className={styles.form__secondarySubmit}
        size="large"
        fullWidth
        loading={loading}
        disabled={emailSent}
        onClick={() => emailVerify(data)}
      >
        {translate("authentication.sendEmailAgain")}
      </Button>
      <Box
        className={styles.form__link}
        component="a"
        onClick={previous}
        aria-label={translate("Have a ")}
      >
        {translate("authentication.haveWrongEmail")}
      </Box>
    </Box>
  );
};

export default Step2;

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import OtpInput from "react-otp-input";

import { TranslateText } from "../../../../../../../translate-text";
import styles from "./styles.module.css";

function TwoFACode({ loading, close, confirm }) {
  const [otp, setOtp] = useState("");

  return (
    <Box className={styles.confirmContainer}>
      <Box className={styles.headerContainer}>
        <Typography className={styles.alertTitle}>
          <TranslateText value="vendorDashboard.settings.2fa.qr.enterCode" />
        </Typography>

        <OtpInput
          value={otp}
          onChange={setOtp}
          disabled={loading}
          numInputs={6}
          inputType="number"
          inputStyle={{
            background: "#f6f6f60d",
            border: "1px solid #f6f6f680",
            borderRadius: "3px",
            color: "#f6f6f6",
            fontSize: "24px",
            height: "38px",
            padding: "8px 12px",
            textAlign: "center",
            transition: "all 200ms cubic-bezier(0, 0, 0.2, 1)",
            width: "22px",
          }}
          shouldAutoFocus={true}
          skipDefaultStyles
          renderSeparator={<div style={{ width: "8px" }} />}
          renderInput={(props) => <input {...props} />}
        />
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          className={clsx(styles.button, styles.confirmButton)}
          disabled={loading}
          onClick={close}
        >
          <TranslateText value="vendorDashboard.settings.2fa.qr.back" />
        </Button>
        <LoadingButton
          className={clsx(styles.button, styles.cancelButton)}
          onClick={() => confirm(otp)}
          loading={loading}
        >
          <TranslateText value="vendorDashboard.settings.2fa.qr.submit" />
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default TwoFACode;

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { getNotificationsCount } from "../../../api/notifications/getNotifications";
import { CURRENCY_SIGN_BY_COUNTRY } from "../../../constants";
import { useSocketProvider } from "../../../context/socket";
import { useTranslate } from "../../../context/translate/translate.context";
import { priceDiscount } from "../../../lib/priceHelper";
import { UiThemeSwitchContainer } from "../../../lib/theme";
import { decreaseQuantity, increaseQuantity } from "../../../store/cartStore";
import { setNotificationsCount } from "../../../store/notificationStore";
import { setTheme } from "../../../store/userStore";
import { priceLabel } from "../../../utils/strings/price";
import Button from "../../UI/Button";
import ItemCounter from "../../cart/ItemCounter";
import LanguageSelector from "../../langPage/LanguageSelector";
import { LogoNew } from "../../logo/LogoNew";
import Search from "../../search/Search";
import Chat from "../../svg/Chat";
import Lock from "../../svg/Lock";
import Logout from "../../svg/Logout";
import ShoppingBasket from "../../svg/ShoppingBasket";
import User from "../../svg/User";
import Wallet from "../../svg/Wallet";
import Payments from "../Footer/Payments";
import Socials from "../Footer/Socials";
import FOOTER_LINKS from "../Footer/constants";
import { DRAWER_MENU, HEADER_MENU, PUBLIC_MENU } from "./constants";
import styles from "./styles.module.css";
import { useGlobalServiceProvider } from "../../../context/global-service";
import StarIcon from '@mui/icons-material/Star';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getTotalReviews } from "../../../api/reviews/getAllReviews";

const Header = () => {
  const { translate } = useTranslate();
  const { resetBalance } = useGlobalServiceProvider();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { unreadChats } = useSocketProvider();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => state.language);
  const walletRedux = useSelector((state) => state.wallet);
  const notifications = useSelector((state) => state.notification);
  const cartItems = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorSettings, setAnchorSettings] = useState(null);
  const [anchorCart, setAnchorCart] = useState(null);

  const cartPopoverId = !!anchorCart ? "cart-popover" : undefined;

  const location = useLocation();

  const isSearchRoute = location.pathname === '/search';

  // TODO: take from user redux after persist was added
  const currentUser = user._id
    ? user
    : JSON.parse(localStorage.getItem("user"));

  //const currencySign = CURRENCY_SIGN_BY_COUNTRY[user.country];

  const currencySign = '₽';

  const walletAmount = `${
    (walletRedux.wallet?.balance || currentUser?.wallet?.balance)
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0
  } ${currencySign}`;

  const isAuth = !!currentUser?._id;

  const menuOptions = useMemo(
    () => [
      {
        label: "userDashboard",
        path: `/profile/${currentUser?.userSlug || ""}`,
        icon: <User />,
      },
      ...HEADER_MENU,
    ],
    [isAuth, selectedLanguage, currentUser]
  );

  const getCartTotal = (cartItems) => {
    const total = cartItems.reduce(
      (acc, { quantity, product: { promocode, price } }) => {
        if (promocode?.discount || promocode?.discount === 0) {
          return (
            acc +
            parseInt(quantity) *
              priceDiscount(parseFloat(price), parseFloat(promocode.discount))
          );
        }

        if (!promocode) {
          return acc + parseFloat(price) * parseInt(quantity);
        }

        return acc;
      },
      0
    );

    return parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const drawerMenuOptions = useMemo(
    () => [
      {
        label: "header.userDashboard",
        path: `/profile/${currentUser?.userSlug || ""}`,
        icon: <AccountCircleOutlinedIcon />,
      },
      {
        label: walletAmount,
        path: `/profile/${currentUser?.userSlug || ""}#wallet`,
        icon: <Wallet />,
        color: "#EDF67D",
      },
      {
        label: cartItems?.length
          ? `${getCartTotal(cartItems)} ${currencySign}`
          : "cart.basket",
        color: cartItems?.length ? "#EDF67D" : undefined,
        path: `/checkout`,
        icon: <ShoppingBasket />,
      },
      ...DRAWER_MENU,
    ],
    [isAuth, walletAmount, cartItems, currencySign]
  );

  const handleOpenUserMenu = (event) => {
    setAnchorSettings(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorSettings(null);
  };

  const handleWalletClick = () => {
    if (currentUser?.role === "admin" || currentUser?.role === "vendor") {
      dispatch(setCurrentTab(3));
    } else if (currentUser?.role === "buyer") {
      dispatch(setCurrentTab(2));
    }

    navigate("/profile" + `/${currentUser?.userSlug}#wallet`);
  };

  const handleSwitchTheme = (theme) => {
    dispatch(setTheme(theme));
  };

  useEffect(() => {
    const checkNotificationsCount = async () => {
      try {
        const response = await getNotificationsCount();
        dispatch(setNotificationsCount({ count: response.data?.count }));
      } catch (error) {
        //console.error(error);
      }
    };

    checkNotificationsCount();
  }, []);

  useEffect(() => {
    resetBalance();
  }, [isAuth]);

  // use effect, if there are cart items, then open the cart pop over
  useEffect(() => {
    if (cartItems?.length > 0) {
      // set achort cart below element with id cartButton
      const element = document.getElementById("cartButton");
      if (element) {
        setAnchorCart(element);
      }
    }
  }, [cartItems]);

  const [totalReviews, setTotalReviews] = useState(null)

  const getAllReviews = async () => {
    try {
      const res = await getTotalReviews()
      if(res.data)
        setTotalReviews(res.data)
    } catch(err) {
      navigate('/404')
    }
  }

  useEffect(() => {
    getAllReviews()
  }, [])

  const formatReviewsText = (totalReviews) => {
    // Function to format numbers with one decimal and Russian suffixes
    const formatNumber = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + ' млн';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + ' тыс';
        } else if (number >= 100) {
            return (number / 100).toFixed(1) + ' сот';
        } else {
            return number;
        }
    }

    return formatNumber(totalReviews);
  }
  const content = isMobile ? (
      <Box className={styles.rightSideContainer}>

        <Box onClick={() => navigate('/reviews')} sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px'
          
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}>
            <StarIcon sx={{ 
                color: "primary.main", 
                fontSize: "16px",
            }} />
            <Typography sx={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: theme.palette.mode === 'dark' ? 'white' : 'black',
            }}>5.0</Typography>
          </Box>
          <Typography component={Link} to="/reviews" sx={{
              textDecoration: 'none',
              color: theme.palette.mode === 'dark' ? 'primary.yellow' : 'primary.main',
              '&hover': {
                cursor: 'pointer'
              },
              fontSize: '12px'
          }}>
            {formatReviewsText(totalReviews)}
          </Typography>
        </Box>
      <IconButton
        className={styles.basketButton}
        onClick={() => navigate("/checkout")}
        aria-label={translate("ariaLabel.navigateToCheckout")}
      >
        <ShoppingBasket />
        {!!cartItems?.length && (
          <Box className={styles.basketBadge} component="span" aria-hidden>
            {cartItems.length}
          </Box>
        )}
      </IconButton>
      <IconButton
        className={styles.menuButton}
        onClick={() => setDrawerOpen(true)}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  ) : (
    <>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '15px'
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px'
        }}>
          <StarIcon sx={{ 
              color: theme.palette.mode === 'dark' ? 'primary.yellow' : 'primary.main', 
              fontSize: "18px",
          }} />
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: '22px',
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          }}>5.0</Typography>
        </Box>
        <Typography component={Link} to="/reviews" sx={{
            textDecoration: 'none',
            fontWeight: '550',
            color: theme.palette.mode === 'dark' ? 'primary.yellow' : 'primary.main',
            '&hover': {
              cursor: 'pointer'
            }
        }}>
          {formatReviewsText(totalReviews)}
        </Typography>
      </Box>
      <UiThemeSwitchContainer
          userData={currentUser}
          dispatchNewUserTheme={handleSwitchTheme}
          theme={theme?.palette?.mode}
          sx={{
            border: '1px solid red'
          }}
        />

      <Search isMobile={false} />
      {/*
      <IconButton
        size="medium"
        onClick={() => navigate("/giveaway")}
        className={styles.competitionButton}
      >
        <EmojiEventsIcon  
          sx={{
            fontSize: "1.5rem",
          }}
        />
      </IconButton>
      */}
      <Box className={styles.rightSideContainer}> 

        
        <LanguageSelector />

        <Divider orientation="vertical" />

        {isAuth && (
          <Button
            color="secondary"
            onClick={() => navigate("/chat")}
            badgeContent={unreadChats.count}
            aria-label={translate("ariaLabel.navigateToChat")}
          >
            <Chat />
          </Button>
        )}

        <Button
          id='cartButton'
          color="secondary"
          startIcon={<ShoppingBasket />}
          badgeContent={cartItems?.length}
          onClick={(event) =>
            cartItems?.length
              ? setAnchorCart(event.currentTarget)
              : navigate("/checkout")
          }
          aria-describedby={cartPopoverId}
        >
          {isAuth ? "" : translate("cart.basket")}
        </Button>

        <Popover
          id={cartPopoverId}
          open={!!anchorCart && !!cartItems?.length}
          anchorEl={anchorCart}
          onClose={() => setAnchorCart(null)}
          data-mui-color-scheme={theme.palette.mode}
          classes={{
            paper: styles.cart__wrapper,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {!!cartItems?.length && (
            <Box className={styles.cart}>
              <Box className={styles.cart__header}>
                <Typography className={styles.cart__heading} variant="h2">
                  {translate("cart.myCart")}
                </Typography>
                <Typography className={styles.cart__itemsCount}>
                  {translate("cart.items", { count: cartItems.length })}
                </Typography>
                <IconButton
                  className={styles.cart__closeButton}
                  onClick={() => setAnchorCart(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <MenuList className={styles.cart__list}>
                {cartItems.map(({ product, quantity }) => (
                  <Box
                    key={product.name}
                    component="li"
                    className={styles.cart__listItem}
                  >
                    <Box
                      className={styles.cart__itemImage}
                      component="img"
                      src={
                        product.thumb !== null &&
                        product.thumb !== undefined &&
                        product.thumb?.length > 0
                          ? product.thumb?.[0]
                          : product.media?.[0]
                      }
                    />
                    <Box className={styles.cart__itemDescription}>
                      <Typography className={styles.cart__itemTitle}>
                        {product.name}
                      </Typography>
                      <Typography className={styles.productContainer__price}>
                        {product.promocode ? (
                          <>
                            <Box
                              className={styles.productContainer__price__strike}
                              component="span"
                            >
                              {priceLabel(product.price, {
                                sign: currencySign,
                              })}
                            </Box>
                            <Box component="span">
                              {priceLabel(
                                product.price -
                                  (product.price * product.promocode.discount) /
                                    100.0,
                                { sign: currencySign }
                              )}
                            </Box>
                          </>
                        ) : (
                          priceLabel(product.price, { sign: currencySign })
                        )}
                      </Typography>
                    </Box>
                    <ItemCounter
                      quantity={quantity}
                      increment={() => dispatch(increaseQuantity(product))}
                      decrement={() => dispatch(decreaseQuantity(product))}
                    />
                  </Box>
                ))}
              </MenuList>
              <Box className={styles.cart__total}>
                <Typography className={styles.cart__totalText}>
                  {translate("cart.totalAmount")}:
                </Typography>
                <Typography className={styles.cart__totalPrice}>
                  {getCartTotal(cartItems)} {currencySign}
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  setAnchorCart(null);
                  return navigate("/checkout");
                }}
                className={styles.cart__buttonText}
                fullWidth
                aria-label={translate("ariaLabel.navigateToCheckout")}
              >
                {translate("cart.proceedToCheckout")}
              </Button>
            </Box>
          )}
        </Popover>

        {isAuth && currentUser?.wallet && (
          <Tooltip
            title={
              <Box
                border
                sx={{
                  width: "200px",
                  height: "auto",
                  maxHeight: "400px",
                  overflowY: "auto",
                  boxShadow: "0px 0px 40px 0px rgba(0,0,0,0.3)",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? "primary.third"
                      : "primary.four",
                  borderRadius: "1rem",
                  border: "1px solid #605E62",
                  marginTop: "-0.5rem",
                  padding: "1rem",
                  zIndex: "999",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      opacity: "0.8",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      color:
                        theme.palette.mode === "dark" ? "#F6F6F6" : "#1F1D22",
                    }}
                  >
                    {translate("wallet.balance")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color:
                            theme.palette.mode === "dark"
                              ? "#FFFFFF"
                              : "#1F1D22",
                        }}
                      >
                        {translate("wallet.pendingBalance")}:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color:
                            theme.palette.mode === "dark"
                              ? "#EDF67D"
                              : "#1F1D22",
                        }}
                      >
                        {currencySign}
                        {currentUser.wallet.pendingBalance
                          ?.toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0}
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        height: "auto",
                        marginX: "1rem",
                        backgroundColor: "gray",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: "0.2rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color:
                            theme.palette.mode === "dark"
                              ? "#FFFFFF"
                              : "#1F1D22",
                        }}
                      >
                        {translate("wallet.pendingWithdrawal")}:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          color:
                            theme.palette.mode === "dark"
                              ? "#EDF67D"
                              : "#1F1D22",
                        }}
                      >
                        {currencySign}
                        {currentUser.wallet.pendingWithdrawals
                          ?.toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0.0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "transparent",
                },
              },
            }}
          >
            <Button
              color="secondary"
              startIcon={<Wallet />}
              onClick={handleWalletClick}
              aria-label={translate("ariaLabel.navigateToCheckout")}
            >
              {walletAmount}
            </Button>
          </Tooltip>
        )}

        {isAuth && (
          <Box>
            <Tooltip title={translate("header.openSettings")}>
              <Button
                onClick={handleOpenUserMenu}
                className={styles.userMenu__button}
                badgeClassName={styles.userMenu__badge}
                badgeContent={notifications.count}
                aria-label={translate("header.openSettings")}
              >
                  <Avatar alt="User avatar" src={currentUser?.avatar} />
              </Button>
            </Tooltip>
            <Menu
              data-mui-color-scheme={theme.palette.mode}
              className={styles.userMenu}
              classes={{
                paper: styles.userMenu__paper,
                list: styles.userMenu__list,
              }}
              anchorEl={anchorSettings}
              open={Boolean(anchorSettings)}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                className={clsx(
                  styles.userMenu__item,
                  styles.userMenu__item_user
                )}
              >
                <Avatar alt="User avatar" src={currentUser?.avatar} />
                <Typography className={styles.userMenu__userName}>
                  {currentUser?.username}
                </Typography>
              </MenuItem>
              {menuOptions.map(({ label, path, icon }) => (
                <MenuItem
                  key={label}
                  className={styles.userMenu__item}
                  onClick={handleCloseUserMenu}
                >
                  <Link className={styles.userMenu__anchor} to={path}>
                    {icon} {translate(`header.${label}`)}{" "}
                    {label === "alerts" && !!Number(notifications.count) && (
                      <Box
                        className={styles.badge}
                        component="span"
                        aria-hidden
                      >
                        {notifications.count}
                      </Box>
                    )}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}

        {!isAuth && (
          <Button
            onClick={() => navigate("/login")}
            aria-label={translate("ariaLabel.navigateToLogin")}
          >
            {translate("header.login")}
          </Button>
        )}
      </Box>
    </>
  );

  return (
    <AppBar className={styles.header}>
      <Toolbar className={styles.toolbar}>
        <Container className={styles.container} disableGutters>
          {/* LOGO */}
          <Box
            className={styles.logoContainer}
            component={Link}
            to={"/"}
            aria-label="Homepage"
          >
            <LogoNew />
            <Box>
              <Typography variant="h1" className={styles.heading}>
                {translate("title")}
              </Typography>
              {/*
              <Typography className={styles.subheading}>
                {translate("header.subTitle")}
              </Typography> */}
            </Box>
          </Box>
          {content}
          {/* Drawer Mobile */}
          <Drawer
            data-mui-color-scheme="dark"
            classes={{ paper: styles.drawerPaper }}
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <Box className={styles.drawerContainer}>
              <Box className={styles.drawerContainer__header}>
                <Box>
                  <Typography variant="h1" className={styles.heading}>
                    {translate("title")}
                  </Typography>
                  <Typography className={styles.subheading}>
                    {translate("header.subTitle")}
                  </Typography>
                </Box>
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <CloseIcon className={styles.drawerContainer__closeIcon} />
                </IconButton>
              </Box>
              {!isAuth && (
                <Box className={styles.drawerContainer__createAccountContainer}>
                  <Typography
                    variant="body1"
                    className={styles.drawerContainer__createAccount}
                  >
                    <Link
                      to="/login"
                      className={styles.drawerContainer__createAccountAnchor}
                    >
                      {translate("header.createAccount")}
                    </Link>
                    {translate("header.createAccountDescription")}
                  </Typography>
                </Box>
              )}
              {/* Add this when search page will be implemented */}
              <Link to="/search" onClick={() => setDrawerOpen(false)}>
                <Search
                  className={styles.drawerContainer__search}
                  isMobile={true}
                />
              </Link>

              <Box
                className={clsx(
                  styles.drawerContainer__drawerMenu,
                  !isAuth && styles.drawerContainer__drawerMenu_border
                )}
              >
                <MenuList>
                  {drawerMenuOptions.map(({ label, path, icon, color }) => (
                    <MenuItem
                      key={label}
                      sx={{ color }}
                      className={styles.drawerContainer__drawerMenuItem}
                    >
                      <Link to={isAuth ? path : "#"}>
                        {icon}
                        {translate(label)}
                      </Link>
                    </MenuItem>
                  ))}
                </MenuList>
                {!isAuth && (
                  <Box className={styles.drawerContainer__lock}>
                    <Lock />
                  </Box>
                )}
              </Box>

              <Divider className={styles.drawerFooter__drawerDivider} />

              {!!PUBLIC_MENU.length && (
                <MenuList className={styles.drawerContainer__drawerPublicMenu}>
                  {PUBLIC_MENU.map(({ label, path, icon }) => (
                    <MenuItem
                      key={label}
                      className={styles.drawerContainer__drawerMenuItem}
                    >
                      <Link to={path}>
                        {icon}
                        {translate(`header.${label}`)}
                      </Link>
                    </MenuItem>
                  ))}
                </MenuList>
              )}

              {!isAuth && (
                <Button
                  className={styles.drawerContainer__loginButton}
                  onClick={() => {
                    navigate("/login");
                    setDrawerOpen(false);
                  }}
                  fullWidth
                >
                  {translate("header.login2")}
                </Button>
              )}

              <Box className={styles.drawerFooter}>
                {isAuth && (
                  <Box className={styles.drawerFooter__logout}>
                    <Link to="/logout">
                      <Logout />
                      {translate("header.exitAccount")}
                    </Link>
                  </Box>
                )}
                <Box className={styles.drawerFooter__actions}>
                  <UiThemeSwitchContainer
                    userData={currentUser}
                    dispatchNewUserTheme={handleSwitchTheme}
                    theme={theme?.palette?.mode}
                  />
                  <LanguageSelector />
                </Box>
                <Divider className={styles.drawerFooter__drawerDivider} />
                <Box
                  className={styles.drawerFooter__bottomAnchors}
                  component="nav"
                >
                  {FOOTER_LINKS.main.map(({ label, path }) => (
                    <Link
                      key={label}
                      className={styles.drawerFooter__bottomAnchor}
                      to={path}
                    >
                      {translate(label)}
                    </Link>
                  ))}
                </Box>
                <Socials className={styles.drawerFooter__socials} />
                <Payments />
                <Box className={styles.drawerFooter__placeholder} />
              </Box>
            </Box>
          </Drawer>
        </Container>
        {(!!isMobile && !isSearchRoute) &&
          <Container className={styles.container__searchBar} disableGutters>
            <Link to="/search" onClick={() => setDrawerOpen(false)}>
              <Search
                className={styles.container__search}
                isMobile={true}
              />
            </Link>
          </Container>
        }
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import OtpInput from "react-otp-input";

import { createWithdrawal } from "../../../../api/finance/Withdraw";
import { useTranslate } from "../../../../context/translate/translate.context";
import { isRuCountry } from "../../../../lib/country";
import MentupModal from "../../../MentupModal/MentupModal";
import ImportIcon from "../../../svg/Import";
import { TranslateText } from "../../../translate-text";
import styles from "./styles.module.css";
import AlertComponent from "../../../AlertComponent";

export default function WithdrawModal({ newWithdrawal }) {
  const { translate } = useTranslate();
  const theme = useTheme();

  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const schema = yup.object().shape({
    amount: yup
      .number()
      .required()
      .min(5)
      .max(10000)
      .test(
        translate("withdrawModal.validationErrors.amount.validValue"),
        translate("withdrawModal.validationErrors.amount.format"),
        (value) => {
          if (
            value.toString().match(/^\d+\.\d{1,2}$/) ||
            value.toString().match(/^\d+$/)
          ) {
            return true;
          }
          return false;
        }
      ),
    withdrawalMethod: yup
      .string(translate("withdrawModal.validationErrors.method.string"))
      .oneOf(
        ["Method 1", "Method 2"],
        translate("withdrawModal.validationErrors.method.option")
      )
      .required(translate("withdrawModal.validationErrors.method.required")),
    methodAddress: yup
      .string(translate("withdrawModal.validationErrors.address.string"))
      .required(translate("withdrawModal.validationErrors.address.required"))
      .max(100, translate("withdrawModal.validationErrors.address.max")),
    notes: yup
      .string()
      .max(500, translate("withdrawModal.validationErrors.notes.max")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const userRedux = useSelector((state) => state.user);

  const submitWithdrawal = async (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = Cookies.get("refreshToken");
    await createWithdrawal(
      user._id,
      { ...data, code: otp },
      token,
      userRedux.country
    )
      .then((res) => {
        newWithdrawal(res.data);
        reset();
        handleClose();
      })
      .catch((error) => {
        //console.log(error);
        setAlertMessage(error.response?.data?.message || error.message);
      });
  };

  const currencySign = () => {
    if (isRuCountry(userRedux.country)) {
      return "₽";
    }
    return "£";
  };

  return (
    <>
      <AlertComponent
        message={alertMessage}
        resetMessageInParent={() => setAlertMessage(null)}
      />
      <Button
        variant="outlined"
        startIcon={
          <ImportIcon
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#1f1d22"}
          />
        }
        className={clsx(styles.export_button)}
        onClick={handleOpen}
      >
        <TranslateText value="vendorDashboard.wallet.withdraw" />
      </Button>

      <MentupModal
        open={open}
        close={handleClose}
        sx={{
          minWidth: "400px",
          width: "fit-content",
          "@media (max-width: 650px)": {
            minWidth: "300px",
          },
        }}
      >
        <Box className={styles.container}>
          <Typography className={styles.title}>
            <TranslateText value="withdrawModal.title" />
          </Typography>

          <form onSubmit={handleSubmit(submitWithdrawal)}>
            <Box
              sx={{
                width: "100%",
                rowGap: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <TextField
                required
                label={`${translate(
                  "withdrawModal.amountInputLabel"
                )} (${currencySign()})`}
                variant="outlined"
                sx={{ width: "100%" }}
                error={errors.amount && true}
                helperText={errors.amount?.message}
                {...register("amount")}
              />
              <FormControl
                sx={{
                  width: "100%%",
                }}
              >
                <InputLabel id={`withdrawal-method-selection-label`}>
                  {translate("withdrawModal.methodInput.label")}
                </InputLabel>
                <Select
                  required
                  labelId={`withdrawal-method-selection-label`}
                  defaultValue=""
                  label={translate("withdrawModal.methodInput.label")}
                  {...register("withdrawalMethod")}
                >
                  <MenuItem value="Method 1">Банковская карта (РФ)</MenuItem>
                  <MenuItem value="Method 2">СБП/Номер телефона (РФ)</MenuItem>
                  <MenuItem value="Method 3">USDT TRC20</MenuItem>
                  <MenuItem value="Method 3">USDT BEP20</MenuItem>
                </Select>
                {errors.withdrawalMethod?.message ? (
                  <FormHelperText
                    sx={{
                      color: `${theme.palette.error.main}`,
                    }}
                  >
                    {errors.withdrawalMethod?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <TextField
                required
                label={translate("withdrawModal.methodAddressInputLabel")}
                variant="outlined"
                error={errors.methodAddress && true}
                helperText={errors.methodAddress?.message}
                {...register("methodAddress")}
              />
              <TextField
                label={translate("withdrawModal.notesInputLabel")}
                variant="outlined"
                multiline
                rows={4}
                error={errors.notes && true}
                helperText={errors.notes?.message}
                {...register("notes")}
              />

              <Typography className={styles.otpTitle}>
                <TranslateText value="withdrawModal.enterOTP" />
              </Typography>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                inputStyle={{
                  margin: "0 auto",
                  background: "#f6f6f60d",
                  border: "1px solid #f6f6f680",
                  borderRadius: "3px",
                  color: "#f6f6f6",
                  fontSize: "24px",
                  height: "38px",
                  padding: "8px 12px",
                  textAlign: "center",
                  transition: "all 200ms cubic-bezier(0, 0, 0.2, 1)",
                  width: "22px",
                }}
                skipDefaultStyles
                renderSeparator={<div style={{ width: "8px" }} />}
                renderInput={(props) => <input {...props} />}
              />
            </Box>
            <Box
              sx={{
                marginTop: "1.5rem",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                className={clsx(styles.add_new_button)}
                type="submit"
              >
                <TranslateText value="withdrawModal.withdrawButtonLabel" />
              </Button>
            </Box>
          </form>
        </Box>
      </MentupModal>
    </>
  );
}

import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  SliderThumb,
  Typography,
} from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// API Calls
import { emailVerify, registerComplete, verifyGoogle } from "../../../api/auth/register";

// Constants
import { CURRENCY_SIGN_BY_COUNTRY } from "../../../constants";
import { FORM_VALIDATIONS } from "../../auth/Register/constants";

import { useTranslate } from "../../../context/translate/translate.context";
import { priceDiscount } from "../../../lib/priceHelper";
import { priceLabel } from "../../../utils/strings/price";

import AlertComponent from "../../AlertComponent";
import MentupStepper from "../../MentupStepper";
import ReviewsStars from "../../Reviews";
import TelegramPayment from "../../TelegramComponents/Payment/TelegramPayment";
import ItemCounter from "../../cart/ItemCounter";
import PromocodeBlock from "./PromocodeBlock";
import styles from "./styles.module.css";

// Register
import GoogleAuthBtn from "../../auth/GoogleAuthBtn";
import VKAuthBtn from "../../auth/VKAuthBtn";
import FormInput from "../../FormInput";
import Button from "../../UI/Button";
import OTPInput from "react-otp-input";
import { setUserSettings } from "../../../store/userSettingsStore";
import { setUser } from "../../../store/userStore";
import StepGoogle from "../../auth/Register/StepGoogle";
import StepVk from "../../auth/Register/StepVK";
import { logInEmail, logInGoogle, logInOtp } from "../../../api/auth/login";
import { useSocketProvider } from "../../../context/socket";
import { checkReady } from "../../../api/payments/checkReady";

const CartModalComponent = ({ defaultProduct }) => {


  const { translate } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { control, handleSubmit, setError } = useForm();


  const { token } = useParams();

  const socket = useSocketProvider();

  const userRedux = useSelector((state) => state.user);

  // Redux
  const [cartItems, setCartItems] = useState(
    defaultProduct ? [{ product: defaultProduct, quantity: 1 }] : []
  );

  const { country } = useSelector((state) => state.user);

  // States

  const [vkFinalStep, setVKFinalStep] = useState(false);
  const [googleFinalStep, setGoogleFinalStep] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);

  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState(false);
  const [telegramPaymentData, setTelegramPaymentData] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState("wata");
  const [currentBalanceAmountToPay, setCurrentBalanceAmountToPay] = useState(0);

  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [step, setStep] = useState(0);

  const [total, setTotal] = useState(0);

  const [otp, setOtp] = useState("");
  const [emailSent, setEmailSent] = useState(true);

  const [data, setData] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));

  const tokenCookie = Cookies.get("refreshToken");

  const paymentWithBalance = currentBalanceAmountToPay >= total;

  const previous = () => {
    setStep((prev) => prev - 1);
  };

  const calculateTotal = () => {
    let total = 0;

    for (const { product, quantity } of cartItems) {
      if (!product.promocode) {
        total = total + quantity * product.price;
      } else if (
        product.promocode &&
        (product.promocode.discount || product.promocode.discount === 0)
      ) {
        total =
          total +
          quantity * priceDiscount(product.price, product.promocode.discount);
      }
    }

    setTotal(total);
  };

  const handleSliderChange = (value) => {
    const newAmount =
      currentBalanceAmountToPay <= parseFloat(user?.wallet?.balance)
        ? value
        : parseFloat(user?.wallet?.balance);

    setCurrentBalanceAmountToPay(newAmount);
  };

  // Vk Use Effect
  useEffect(() => {
    if (token) {
      setStep(2);

      const params = location.search.split("?email=")[1];

      const username = params.split("?user=")[1] || "";
      const email = params.split("?user=")[0] || "";

      Cookies.set("refreshToken", token);

      setData({
        email,
        username,
      });

      return;
    }

    const searchParams = new URLSearchParams(
      window.location.hash.replace("#", "?")
    );

    const accessToken = searchParams.get("access_token");
    const userId = searchParams.get("user_id");

    if (accessToken && userId) {
      setVKFinalStep(true);
    } else {
      setStep(0);
    }
  }, [token, location.search]);

  useEffect(() => {
    setStep(0);
    setPaymentMethod("wata");
    setDiscountedTotal(total - currentBalanceAmountToPay);
    setData({});
    setEmailSent(false)
  }, [currentBalanceAmountToPay]);

  useEffect(() => {
    calculateTotal();
  }, [cartItems]);

  useEffect(() => {
    if (total < 1) {
      setPaymentMethod("");
    } else {
      setPaymentMethod("wata");
    }

    /*
    if (total && !initialSliderPriceSet) {
      handleSliderChange(total);
      setInitialSliderPriceSet(true);
    } */
  }, [total]);


  useEffect(() => {
    if (emailSent && step === 2) {
      const timer = setTimeout(() => {
        setEmailSent(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [emailSent, step]);

  const calculateGrandTotal = () => {
    if (currentBalanceAmountToPay > 0 && currentBalanceAmountToPay <= total) {
      return (total - (total - discountedTotal))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }

    return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const getReferralData = () => {
    const referral = localStorage.getItem("referral");

    if (!referral) {
      return {};
    }

    return JSON.parse(referral);
  };

  const getInviteData = () => {
    const invite = localStorage.getItem("hasInvite");

    if (!invite) {
      return {};
    }
    return JSON.parse(invite);
  };

  const handlePaymentMethod = async (method) => {
    switch (method) {
      case "balance":
        const payUrl = process.env.NODE_ENV.includes("production")
          ? process.env.REACT_APP_SERVER_URL
            ? `${process.env.REACT_APP_SERVER_URL}/payment/success`
            : ""
          : "http://localhost:3030/api/payment/success";

        const cartItemsFormatted = cartItems.map((cartItem) => {
          // Create a new cartItem with updated user data
          return {
            ...cartItem,
            product: {
              ...cartItem.product,
              reviews: [],
              category: {
                _id: cartItem.product._id, 
              },
              user: {
                _id: cartItem.product.user._id,
                userSlug: cartItem.product.user.userSlug,
                avatar: cartItem.product.user.avatar,
                username: cartItem.product.user.username
              }
            }
          };
        });
        const body = {
          userId: user._id,
          userEmail: user.email,
          cart: cartItemsFormatted,
          type: "balance",
          currency: "RUB",
          country: "RU",
          total: calculateGrandTotal(),
          discountCode: discountCode,
          balanceUsed: currentBalanceAmountToPay,
          ...getReferralData(),
          ...getInviteData()
        };

        try {
          const res = await axios.post(payUrl, body, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("refreshToken")}`,
            },
          });

          if (currentBalanceAmountToPay > 0) {
            const user = JSON.parse(localStorage.getItem("user"));

            const newUser = {
              ...user,
              wallet: {
                ...user.wallet,
                balance:
                  parseFloat(user.wallet.balance) -
                  parseFloat(currentBalanceAmountToPay),
              },
            };

            localStorage.setItem("user", JSON.stringify(newUser));
            //dispatch(setUserUpdate(newUser));
          }

          // dispatch(resetCart());
          localStorage.removeItem("referral");
          localStorage.removeItem("hasInvite");

          const orderId = res.data
          navigate(`/orderb/${orderId}?from=paidBalance`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } catch (error) {
          setAlert({
            message:
              error.response?.data?.message ||
              translate("error404.somethingWentWrong"),
            severity: "error",
          });
          console.error(error);
        }

        return;

      default:
        break;
    }
  };

  const handleApplyDiscount = async (discountCode) => {
    try {
      const payUrl = process.env.NODE_ENV.includes("production")
        ? process.env.REACT_APP_SERVER_URL + "/promo/discount/"
        : "http://localhost:3030/api/promo/discount/";

        const cartItemsFormatted = cartItems.map((cartItem) => {
          // Create a new cartItem with updated user data
          return {
            ...cartItem,
            product: {
              ...cartItem.product,
              user: {
                _id: cartItem.product.user._id,
                userSlug: cartItem.product.user.userSlug,
                avatar: cartItem.product.user.avatar,
                username: cartItem.product.user.username
              }
            }
          };
        });
      
      const body = {
        cart: cartItemsFormatted,
        discountCode,
        country,
      };

      const {
        data: { status, total: responseTotal, discount },
      } = await axios.post(payUrl, body);

      if (status === "success") {
        setDiscountCode(discountCode);

        setTotal(parseFloat(responseTotal));
        setDiscount(parseFloat(discount));
        setAlert({
          message: translate("cartPage.promocodeAppliedMessage"),
          severity: "success",
        });
        setPaymentMethod("");
        setStep(0);
      } else {
        setDiscountCode("");
        setDiscount(0);
      }
    } catch (err) {
      //console.error(err);
      setAlert({
        message: err.response.data.message,
        severity: "error",
      });
      calculateTotal();
      setDiscount(0);
      setDiscountCode("");
      setStep(0);
      setPaymentMethod("");
    } finally {
      setCurrentBalanceAmountToPay(0);
    }
  };

  const handlePaymentReady = async () => {
    setLoading(true);

    if (!tokenCookie) {
      //return navigate("/login?from=cart");
      setStep(1)
      setLoading(false)
      return 
    }

    try {
      if (paymentWithBalance) {
        return await handlePaymentMethod("balance");
      }
      const cartItemsFormatted = cartItems.map((cartItem) => {
        // Create a new cartItem with updated user data
        return {
          ...cartItem,
          product: {
            ...cartItem.product,
            reviews: [],
            category: {
              _id: cartItem.product._id, 
            },
            user: {
              _id: cartItem.product.user._id,
              userSlug: cartItem.product.user.userSlug,
              avatar: cartItem.product.user.avatar,
              username: cartItem.product.user.username
            }
          }
        };
      });

      const data = {
        cart: cartItemsFormatted,
        country
      };

      //console.log(data)

      //format the cartItems.product to include only the product.user._id from user, 
      

      await checkReady(data)

      setStep(1);
    } catch (err) {
      navigate('/404')
      //console.error(err);
      //setStep(1)
    } finally {
      setLoading(false);
    }
  };

  const handlePayStripe = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };
    setTelegramPaymentData(body);
    setShowTelegramModal(true);
  };

  const handleChoosePaymentMethod = (method) => {
    switch (method) {
      // TODO: Add additional logics for payment methods in case if needed

      default:
        return setPaymentMethod(method);
    }
  };

  const handlePayTinkoff = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/wata`;

    const cartItemsFormatted = cartItems.map((cartItem) => {
      // Create a new cartItem with updated user data
      return {
        ...cartItem,
        product: {
          ...cartItem.product,
          reviews: [],
          category: {
            _id: cartItem.product._id, 
          },
          user: {
            _id: cartItem.product.user._id,
            userSlug: cartItem.product.user.userSlug,
            avatar: cartItem.product.user.avatar,
            username: cartItem.product.user.username
          }
        }
      };
    });

    const body = {
      cart: cartItemsFormatted,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");
    } catch (error) {
      navigate('/404')
    }
  };

  const handlePayYooMoney = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/yooMoney`;

    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");
    } catch (error) {
      navigate('/404')
    }
  };

  const handlePayTransfer = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/transfer`;

    const body = {
      cart: cartItems,
      userId: user._id,
      country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");

      if (response.data?.paymentCode) {
        navigate(`/payment/transfer/${response.data.paymentCode}`);
      }
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayLava = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const requestUrl = `${
      process.env.REACT_APP_SERVER_URL || "http://localhost:3030/api"
    }/payment/lava`;

    const body = {
      cart: cartItems,
      userId: user._id,
      country: country,
      promocode: discountCode,
      balanceUsed: currentBalanceAmountToPay,
      total: calculateGrandTotal(),
      ...getReferralData(),
      ...getInviteData()
    };

    try {
      const response = await axios.post(requestUrl, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      });

      if (response.data?.redirectUrl) {
        window.location.replace(response.data.redirectUrl);
      }

      localStorage.removeItem("referral");
      localStorage.removeItem("hasInvite");
    } catch (error) {
      setAlert({
        message:
          error.response?.data?.message ||
          translate("error404.somethingWentWrong"),
        severity: "error",
      });
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = () => {
    setLoading(true);
    switch (paymentMethod) {
      case "transfer":
        return handlePayTransfer();

      case "lava":
        return handlePayLava();

      case "stripe":
        return handlePayStripe();

      case "wata":
        return handlePayTinkoff();

      case "yooMoney":
        return handlePayYooMoney();

      default:
        setAlert({
          message: translate("cartPage.payment.labels.paymentMethodNotChosen"),
          severity: "error",
        });
        setLoading(false);
        return;
    }
  };

  const handleRedirect = (href) => () => {
    if (href) {
      navigate(href);
    }
  };

  const next = (data) => {
    if (data) {
      setData((prev) => ({ ...prev, ...data }));
    }
    setStep((prev) => prev + 1);
  };

  const onGoogleSubmit = async (token) => {
    try{
    setLoading(true);

      const res = await logInGoogle({
        token,
        country: userRedux.country,
        ip: userRedux.ip,
      });
      const user = res.data.user;
      const userSettings = res.data.userSettings;

      if (user && userSettings) {
        localStorage.setItem("userSettings", JSON.stringify(userSettings));
        localStorage.setItem("user", JSON.stringify(user));

        dispatch(setUser(user));
        dispatch(setUserSettings(userSettings));

        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", userSettings.refreshToken, {
          expires: maxDate
        });

        const token = userSettings.refreshToken;
        socket.authenticate(token);
      }

      //navigate(`/profile/${user.userSlug}`);
      // yandex metrika
      ym(97684982,'reachGoal','open')
    } catch (err) {
      /*
      console.error(err);
      setLoading(false);

      setError("email", { message: "" });

      //if error status 4-02 , then message that user is banner
      if(err.response.status === 402) {
        return setAlertMessage('User is banned');
      }
      if (err.response.status === 401 || err.response.status === 400) {
        return setAlertMessage(translate("errorMessages.invalidEmailPassword"));
      }

      setAlertMessage(translate("errorMessages.unexpectedError")); */
      // register google
      try {
        setLoading(true);
        await verifyGoogle({ token });
  
        setData({ ...data, token });
  
        setGoogleFinalStep(true)
        next()
      } catch (error) {
        setError("email", { message: error?.response?.data?.email });
        //console.error(error);
      } finally {
        setLoading(false);
      }
    } finally {
      setLoading(false)
    }
  };

  const logIn = useCallback(({ data: { user, userSettings } }) => {

    const tokenCookie = userSettings.refreshToken;

    if (user && userSettings) {
      dispatch(setUser(user));
      dispatch(setUserSettings(userSettings));

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userSettings", JSON.stringify(userSettings));

      if (tokenCookie) {
        setStep(3);
        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", tokenCookie, {
          expires: maxDate
        });
        setTimeout(() => {
          ym(97684982,'reachGoal','registraciya')
          //navigate("/profile/" + user.userSlug, { replace: true });
        }, 2000);
      }
    }
  }, []);

  const [isRegister, setIsRegister] = useState(false)
  const [loginData, setLoginData] = useState()
  const [username, setUsername] = useState("")

  const onSubmit = async ({ email }) => {
    try {
      setLoading(true);

      const response = await logInEmail({ email });
      if (response.status !== 200) {
        setError("email", {
          message: translate("errorMessages.invalidEmail"),
        });
        return;
      } else {
        setLoginData({ email });
        next();
      }
      
    } catch (err) { // register user if it does not exist
      await emailVerify({ email });
      setLoginData({email: email})
      setIsRegister(true)
      next();
    } finally {
      setLoading(false)
    }
  };

  const handleRegister = async () => {
    try {
      //console.log(data)
      setLoading(true);

      const invite = JSON.parse(localStorage.getItem('hasInvite'))

      let dataToSend = {
        ...data,
        ...loginData,
        username: username,
        country: country,
        otp,
      }
      if(invite){
        dataToSend.invite = invite.fromUser
      }
      const response = await registerComplete(dataToSend);

      logIn(response);
    } catch (error) {
      //console.error(error);
      setAlertMessage(
        error?.response?.data?.invalidOtp
          ? translate("errorMessages.invalidOtp")
          : error?.response?.data?.otpExpired
          ? translate("errorMessages.otpExpired")
          : translate("authentication.unexpectedErrorOccurred")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);

      const loginRes = await logInOtp({ ...loginData, otp });
      if (loginRes.status !== 200) {
        setAlertMessage(
          translate(
            loginRes.data.invalidOtp
              ? "errorMessages.invalidOtp"
              : loginRes.data.otpExpired
              ? "errorMessages.otpExpired"
              : "errorMessages.unexpectedError"
          )
        );
        return;
      }
      const user = loginRes.data.user;
      const userSettings = loginRes.data.userSettings;

      if (user && userSettings) {
        localStorage.setItem("userSettings", JSON.stringify(userSettings));
        localStorage.setItem("user", JSON.stringify(user));

        dispatch(setUser(user));
        dispatch(setUserSettings(userSettings));
        const maxDate = new Date(2147483647 * 1000); // Convert seconds to milliseconds
        Cookies.set("refreshToken", userSettings.refreshToken, {
          expires: maxDate
        });
        const token = userSettings.refreshToken;
        socket.authenticate(token);
      } 
      if (location.href.includes("from=cart")) {
        navigate("/checkout");
        return;
      }

      //navigate(`/profile/${user.userSlug}`);
      // yandex metrika
      ym(97684982,'reachGoal','open')
    } catch (err) {
      setAlertMessage(translate("errorMessages.unexpectedError"));
    } finally {
      setLoading(false);
    }
  };

  const currencySign = CURRENCY_SIGN_BY_COUNTRY[country] || "₽";

  return (
    <Grid container spacing={2} className={styles.mainGrid}>
      <AlertComponent
        message={alert?.message}
        severity={alert?.severity || "error"}
        resetMessageInParent={() => setAlert(null)}
      />
      <Grid item xs={12}>
        {cartItems.map(({ product, quantity }) => {
          const {
            name,
            media,
            thumb,
            user,
            category,
            promocode,
            price,
            currency,
            soldOut,
            slug,
            type,
          } = product;

          return (
            <Box key={name} className={styles.productContainerWrapper}>
              <Box
                className={clsx(
                  styles.productContainer,
                  soldOut && styles.productContainer_soldOut
                )}
              >
                <Box className={styles.productContainer__productInfoWrapper}>
                  <Box className={styles.productContainer__productInfo}>
                    <Box
                      className={styles.productContainer__image}
                      component="img"
                      onClick={handleRedirect(
                        slug &&
                          user.userSlug &&
                          `/profile/${user.userSlug}/${slug}`
                      )}
                      src={
                        thumb !== null &&
                        thumb !== undefined &&
                        thumb.length > 0
                          ? thumb?.[0] || "/images/product_placeholder.png"
                          : media?.[0] || "/images/product_placeholder.png"
                      }
                      alt={`${name} image`}
                    />
                    <Box sx={{ maxWidth: "172px" }}>
                      <Typography
                        noWrap
                        className={styles.productContainer__title}
                        onClick={handleRedirect(
                          slug &&
                            user.userSlug &&
                            `/profile/${user.userSlug}/${slug}`
                        )}
                      >
                        {name}
                      </Typography>
                      <Box className={styles.productContainer__chipsContainer}>
                        <Chip
                          className={styles.productContainer__chip}
                          key={`${type.slug}`}
                          label={type.name}
                          onClick={handleRedirect(
                            `/games/${category.slug}/${type.slug}`
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.productContainer__dataContainer}>
                    <Typography
                      className={styles.productContainer__owner}
                      onClick={handleRedirect(
                        user.userSlug && `/profile/${user.userSlug}`
                      )}
                      aria-label="Go to user profile"
                      component={Link}
                    >
                      <Avatar
                        className={styles.productContainer__avatar}
                        alt={`${user.username} avatar`}
                        src={user.avatar}
                      />
                      <Box sx={{ maxWidth: "72px" }}>
                        <Typography noWrap>{user.username}</Typography>
                        <ReviewsStars
                          size="small"
                          reviews={user.reviews}
                          starsOnly
                        />
                      </Box>
                    </Typography>
                    <Typography className={styles.productContainer__price}>
                      {promocode ? (
                        <>
                          <Box
                            className={styles.productContainer__price__strike}
                            component="span"
                          >
                            {priceLabel(price, { currency })}
                          </Box>
                          <Box component="span">
                            {priceLabel(
                              price - (price * promocode.discount) / 100.0,
                              { currency }
                            )}
                          </Box>
                        </>
                      ) : (
                        priceLabel(price, { currency })
                      )}
                    </Typography>
                    <ItemCounter
                      quantity={quantity}
                      increment={() =>
                        setCartItems((prev) => [
                          {
                            product: prev[0].product,
                            quantity: prev[0].quantity + 1,
                          },
                        ])
                      }
                      decrement={() =>
                        setCartItems((prev) => [
                          {
                            product: prev[0].product,
                            quantity: Math.max(1, prev[0].quantity - 1),
                          },
                        ])
                      }
                    />
                  </Box>
                </Box>
              </Box>
              {soldOut && (
                <Typography className={styles.productContainer__soldOutLabel}>
                  {translate("cartPage.soldOut")}
                </Typography>
              )}
            </Box>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <Box className={styles.formContainer}>
          <PromocodeBlock submit={handleApplyDiscount} />
          <Box className={styles.priceSummary}>
            <Box sx={{ width: "100%" }}>
              <MentupStepper
                getStepTitle={(index) =>
                  `${translate("vendorDashboard.topUp.steps.stepper")} ${
                    index + 1
                  }`
                }
                current={step}
                steps={tokenCookie ? 2 : 4}
              />
            </Box>
            <Typography className={styles.priceSummary__title}>
              {translate(
                tokenCookie
                ? step === 0
                  ? "cartPage.orderSummary.title"
                  : "cartPage.orderSummary.secondTitle"
                : step === 2 && googleFinalStep
                ? null  // Or you can use '' if you prefer an empty string
                : step === 2
                ? "authentication.checkYourEmailInviteLink"
                : step === 0
                ? "cartPage.orderSummary.title"
                : step === 1
                ? "Вход"
                : "cartPage.orderSummary.secondTitle"
                
              )}
            </Typography>
            {tokenCookie ? (
              <>
                {step === 0 ? (
                  <>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridTemplateRows: user?.wallet?.balance
                          ? "auto auto"
                          : "auto",
                        gap: "16px",
                        "& p": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      {/*
                      <Typography className={styles.colorTheme}>
                        {translate("cartPage.orderSummary.labels.subtotal")}
                      </Typography>
                      
                      <Typography
                        className={styles.colorTheme}
                        sx={{ justifySelf: "flex-end", paddingLeft: "23px" }}
                      >
                        {total || total === 0
                          ? `${total
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                      */}
                      {user?.wallet?.balance && (
                        <>
                          <Box>
                            <Typography className={styles.colorTheme}>
                              {translate(
                                "cartPage.orderSummary.labels.useMentupBalance"
                              )}
                            </Typography>
                            <Slider
                              className={styles.priceSummary__balanceSlider}
                              value={currentBalanceAmountToPay}
                              slots={{
                                thumb: ({ children, ...props }) => (
                                  <SliderThumb {...props}>
                                    {children}
                                    <Box
                                      className={
                                        styles.priceSummary__sliderThumbDot
                                      }
                                      component="span"
                                    />
                                  </SliderThumb>
                                ),
                              }}
                              min={0}
                              step={0.01}
                              max={
                                parseFloat(user?.wallet?.balance) > total
                                  ? total
                                  : parseFloat(user?.wallet?.balance)
                              }
                              onChange={(e, value) => {
                                e.preventDefault();

                                handleSliderChange(value);
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>

                          <Typography className={styles.priceSummary__balancePrice}>
                            {`${currentBalanceAmountToPay.toFixed(
                              2
                            )} ${currencySign}`}
                          </Typography>
                        </>
                      )}
                    </Box>
                    {/*
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.serviceFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box>
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.paymentFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box>
                    */}
                    {discount > 0 && (
                      <Box className={styles.priceSummary__priceContainer}>
                        <Typography>
                          {translate("cartPage.orderSummary.labels.discount")}
                        </Typography>
                        <Typography>
                          {total || total === 0
                            ? `-${(total * discount) / 100} ${currencySign}`
                            : `${0.0} ${currencySign}`}
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box>
                    <RadioGroup
                      className={styles.priceSummary__radioGroup}
                      name="paymentMethod"
                      defaultValue="top"
                      sx={{ textAlign: "center" }}
                    >
                      {/* <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="transfer"
                        control={<Radio />}
                        label="Transfer"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("transfer")}
                      />
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="lava"
                        control={<Radio />}
                        label="Lava"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("lava")}
                      /> */}
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="Telegram"
                        control={<Radio />}
                        label="Telegram"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("stripe")}
                      /> */}
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="wata"
                        control={
                          <Radio
                            checked={true}
                            onChange={() => handleChoosePaymentMethod("wata")}
                          />
                        }
                        label={translate("cartPage.orderSummary.labels.tinkoff")}
                        labelPlacement="top"
                      />
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="yooMoney"
                        control={<Radio />}
                        label={translate("cartPage.orderSummary.labels.yooMoney")}
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("yooMoney")}
                    /> */}
                    </RadioGroup>
                  </Box>
                )}
              </> 
            ) : (
              <>
                {step === 0 ? (
                    <>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridTemplateRows: user?.wallet?.balance
                          ? "auto auto"
                          : "auto",
                        gap: "16px",
                        "& p": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      {/*
                      <Typography className={styles.colorTheme}>
                        {translate("cartPage.orderSummary.labels.subtotal")}
                      </Typography>
                      
                      <Typography
                        className={styles.colorTheme}
                        sx={{ justifySelf: "flex-end", paddingLeft: "23px" }}
                      >
                        {total || total === 0
                          ? `${total
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                      */}
                      {user?.wallet?.balance && (
                        <>
                          <Box>
                            <Typography className={styles.colorTheme}>
                              {translate(
                                "cartPage.orderSummary.labels.useMentupBalance"
                              )}
                            </Typography>
                            <Slider
                              className={styles.priceSummary__balanceSlider}
                              value={currentBalanceAmountToPay}
                              slots={{
                                thumb: ({ children, ...props }) => (
                                  <SliderThumb {...props}>
                                    {children}
                                    <Box
                                      className={
                                        styles.priceSummary__sliderThumbDot
                                      }
                                      component="span"
                                    />
                                  </SliderThumb>
                                ),
                              }}
                              min={0}
                              step={0.01}
                              max={
                                parseFloat(user?.wallet?.balance) > total
                                  ? total
                                  : parseFloat(user?.wallet?.balance)
                              }
                              onChange={(e, value) => {
                                e.preventDefault();

                                handleSliderChange(value);
                              }}
                              valueLabelDisplay="auto"
                            />
                          </Box>

                          <Typography className={styles.priceSummary__balancePrice}>
                            {`${currentBalanceAmountToPay.toFixed(
                              2
                            )} ${currencySign}`}
                          </Typography>
                        </>
                      )}
                    </Box>
                    {/*
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.serviceFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box>
                    <Box className={styles.priceSummary__priceContainer}>
                      <Typography>
                        {translate("cartPage.orderSummary.labels.paymentFee")}
                      </Typography>
                      <Typography>
                        {total || total === 0
                          ? `${(0.0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${currencySign}`
                          : `${0.0} ${currencySign}`}
                      </Typography>
                    </Box> */}
                    {discount > 0 && (
                      <Box className={styles.priceSummary__priceContainer}>
                        <Typography>
                          {translate("cartPage.orderSummary.labels.discount")}
                        </Typography>
                        <Typography>
                          {total || total === 0
                            ? `-${(total * discount) / 100} ${currencySign}`
                            : `${0.0} ${currencySign}`}
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : step === 1 ? ( // ask for user email and nickname, also register with vka nd google
                  <>
                    <Box
                      className={styles.form}
                      component="form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormInput
                        type="email"
                        name="email"
                        label={translate("authentication.email")}
                        control={control}
                        disabled={loading}
                        className={styles.form__input}
                        rules={FORM_VALIDATIONS.email(translate)}
                      />
                      
                      <Button
                        className={styles.form__submit}
                        type="submit"
                        size="large"
                        fullWidth
                        loading={loading}
                      >
                        {translate("authentication.continue")}
                      </Button>
                 
                      <GoogleAuthBtn
                        title="registration.steps.1.continueGoogle"
                        onToken={onGoogleSubmit}
                      />
                      {/*
                        <VKAuthBtn 
                          title="registration.steps.1.continueVK"
                          from='buynow'
                          productLink={window.location.pathname}
                        />
                      */}
                      {/*
                      <Box className={styles.form__link} component={Link} to="/login">
                        {translate("authentication.alreadyHaveAnAccount")}
                      </Box> */}
                      <Typography className={styles.form__confirmation}>
                        {translate("authentication.byRegisteringYouAgree")}{" "}
                        <Box component={NavLink} to="/info/laws/license-agreement">
                          {translate("authentication.licenseAgreement")}
                        </Box>
                        ,{" "}
                        <Box component={NavLink} to="/info/laws/privacy-policy">
                          {translate("authentication.privacyPolicy")}
                        </Box>{" "}
                        {translate("authentication.and")}{" "}
                        <Box component={NavLink} to="/info/laws/rules">
                          {translate("authentication.rules")}
                        </Box>
                      </Typography>
                    </Box>
                  </>
                ) : step === 2 ? (
                  <>
                  {googleFinalStep ? (
                    <>
                      <StepGoogle 
                        loading={loading}
                        data={data}
                        setLoading={setLoading}
                        logIn={logIn}
                        next={next}
                      />
                    </>
                  ) : vkFinalStep ? (
                    <>
                      <StepVk
                        loading={loading}
                        setLoading={setLoading}
                        logIn={logIn}
                      />
                    </>
                  ) : (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '16px',
                    }}>
                      <AlertComponent
                        message={alertMessage}
                        resetMessageInParent={() => setAlertMessage(null)}
                      />
                      {isRegister && (
                        <FormInput
                          name="username"
                          label={translate("authentication.nickname")}
                          className={styles.form__input}
                          onChange={(e) => setUsername(e.target.value)}
                      /> )}
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={styles.otpInput}
                        shouldAutoFocus={true}
                        skipDefaultStyles
                        inputType="number"
                        renderSeparator={<div style={{ width: "8px" }} />}
                        renderInput={(props) => <input {...props} />}
                      />

                      <Button
                        size="large"
                        fullWidth
                        loading={loading}
                        style={{ marginTop: "16px"}}
                        className={styles.form__submit}
                        onClick={() => isRegister ? handleRegister() : handleLogin()}
                      >
                        {translate("authentication.signInToYourAccount")}
                      </Button>
                      {/*
                      <Button
                        className={styles.form__secondarySubmit}
                        size="large"
                        fullWidth
                        loading={loading}
                        disabled={emailSent}
                        onClick={() => emailVerify(data)}
                      >
                        {translate("authentication.sendEmailAgain")}
                      </Button>
                      */}
                      <Box
                        className={styles.form__link}
                        component="a"
                        onClick={previous}
                        aria-label={translate("Have a ")}
                      >
                        {translate("authentication.haveWrongEmail")}
                      </Box>
                    </Box>
                  )}
                  </>
                ) : (
                  <Box>
                    <RadioGroup
                      className={styles.priceSummary__radioGroup}
                      name="paymentMethod"
                      defaultValue="top"
                      sx={{ textAlign: "center" }}
                    >
                      {/* <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="transfer"
                        control={<Radio />}
                        label="Transfer"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("transfer")}
                      />
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="lava"
                        control={<Radio />}
                        label="Lava"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("lava")}
                      /> */}
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="Telegram"
                        control={<Radio />}
                        label="Telegram"
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("stripe")}
                      /> */}
                     <FormControlLabel
                      className={styles.priceSummary__radio}
                      value="wata"
                      control={
                        <Radio
                          checked={true}
                          onChange={() => handleChoosePaymentMethod("wata")}
                        />
                      }
                      label={translate("cartPage.orderSummary.labels.tinkoff")}
                      labelPlacement="top"
                    />
                      {/*
                      <FormControlLabel
                        className={styles.priceSummary__radio}
                        value="yooMoney"
                        control={<Radio />}
                        label={translate("cartPage.orderSummary.labels.yooMoney")}
                        labelPlacement="top"
                        onChange={() => handleChoosePaymentMethod("yooMoney")}
                    /> */}
                    </RadioGroup>
                  </Box>
                )}
              </>
            )}
            <Divider />
            <Box className={styles.priceSummary__priceContainer}>
              <Typography className={styles.priceSummary__total}>
                {translate("cartPage.orderSummary.labels.total")}
              </Typography>
              <Typography className={styles.priceSummary__totalPrice}>
                {total
                  ? `${calculateGrandTotal()} ${currencySign}`
                  : `${0.0} ${currencySign}`}
              </Typography>
            </Box>
          </Box>
          {!tokenCookie ? (
            <>
              {step === 0 ? (
                <Button
                  onClick={handlePaymentReady}
                  sx={{ color: "white" }}
                  loading={loading}
                >
                  {translate(
                    paymentWithBalance
                      ? "cartPage.payment.labels.payWithBalanceButton"
                      : "cartPage.payment.labels.proceedToChoosingPaymentMethod"
                  )}
                </Button>
              ) : step === 3 ? (
                <Button
                  onClick={handlePayment}
                  sx={{ color: "white" }}
                  loading={loading}
                >
                  {translate("cartPage.payment.labels.proceedButton")}
                </Button>
              ) : null
             }
            </>
          ) : (
            <>
              {step === 0 ? (
                <Button
                  onClick={handlePaymentReady}
                  sx={{ color: "white" }}
                  loading={loading}
                >
                  {translate(
                    paymentWithBalance
                      ? "cartPage.payment.labels.payWithBalanceButton"
                      : "cartPage.payment.labels.proceedToChoosingPaymentMethod"
                  )}
                </Button>
              ) : (
                <Button
                  onClick={handlePayment}
                  sx={{ color: "white" }}
                  loading={loading}
                >
                  {translate("cartPage.payment.labels.proceedButton")}
                </Button>
              )}
            </>
          )}

          <Box className={styles.bottomPlaceholder} />
          {/*
          <PaymentInformation />
          */}
        </Box>
      </Grid>

      <TelegramPayment
        cart={telegramPaymentData}
        open={showTelegramModal}
        setAlert={setAlert}
        close={() => {
          setLoading(false);
          setShowTelegramModal(false);
        }}
      />
    </Grid>
  );
};

export default CartModalComponent;

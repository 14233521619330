import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getUserDashboardInfo } from "../../api/user/getDashboardInfo";
import AddProductModal from "./DashboardComponents/AddProductModal";
import UserInfoCard from "./DashboardComponents/UserInfoCard";
import UserInfoTabListings from "./DashboardComponents/UserInfoTabListings";
import UserInfoTabPurchases from "./DashboardComponents/UserInfoTabPurchases";
import UserInfoTabSales from "./DashboardComponents/UserInfoTabSales";
import UserInfoTabSettings from "./DashboardComponents/UserInfoTabSettings";
import UserInfoTabWallet from "./DashboardComponents/UserInfoTabWallet";
import UserInfoTabs from "./DashboardComponents/UserInfoTabs";

import BecomeSeller from "./BecomeSeller";
import {
  CURRENT_USER_TABS,
  OTHER_USER_TABS,
} from "./DashboardComponents/UserInfoTabs/constants";
import SEOHelmet from "../../lib/SEOHelmet";
import UserInfoTabReviews from "./DashboardComponents/UserInfoTabReviews";

const VendorDashboard = () => {
  const { userSlug } = useParams();

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const isCurrentUser = currentUser?.userSlug === userSlug;

  const [user, setUser] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [showAddProduct, setShowAddProduct] = useState(false);

  const navigate = useNavigate()

  const tabs = useMemo(() => {
    if (!user?.role) {
      return [];
    }

    if (isCurrentUser) {
      // if user.ratingCount === 0, dont show reviews tab
      if (user.ratingCount === 0) {
        return CURRENT_USER_TABS[user.role]?.filter((tab) => tab !== "reviews") || []
      } else {
        return CURRENT_USER_TABS[user.role] || [];
      }
    }

    if(user.ratingCount === 0) {
      return OTHER_USER_TABS[user.role].filter((tab) => tab !== "reviews");
    } else {
      return OTHER_USER_TABS[user.role] || [];
    }
  }, [isCurrentUser, user?.role]);

  const getInfo = useCallback(async (s) => {
    try {
      const response = await getUserDashboardInfo(s);
      setUser(response.data);
      //console.log(user)
    } catch (error) {
      navigate('/404')
    }
  }, []);

  useEffect(() => {
    if (userSlug) {
      getInfo(userSlug);
    }
  }, [userSlug]);

  useEffect(() => {
    const shouldOpenTab = window.location.hash?.split("#")[1]?.split("?")[0];

    if (tabs.length) {
      setActiveTab(tabs.includes(shouldOpenTab) ? shouldOpenTab : tabs[0]);
    }
  }, [tabs.length, window.location.hash]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginBottom: "100px",
        overflowX: 'hidden'
      }}
    >
      <SEOHelmet
        title={'Mentup - ' + user?.username}
        name={user?.username}
        type="profile"
      />

      <UserInfoCard
        user={user}
        isCurrentUser={isCurrentUser}
        updateLocalUser={(v) =>
          setUser((prev) => ({
            ...prev,
            ...v,
          }))
        }
      />
      
      {!!user && (
        <UserInfoTabs
          tabs={tabs}
          isCurrentUser={isCurrentUser}
          selectedTab={activeTab}
          onTabChange={(v) => {
            setActiveTab(v);
            window.location.hash = v;
          }}
          addProduct={() => setShowAddProduct(true)}
        />
      )}

      {activeTab === "listings" && <UserInfoTabListings user={user} />}
      {activeTab === "purchases" && (
        <UserInfoTabPurchases openTransactions={() => setActiveTab("wallet")} />
      )}

      {activeTab === "becomeSeller" && <BecomeSeller />}
      {activeTab === "sellingHistory" && (
        <UserInfoTabSales openTransactions={() => setActiveTab("wallet")} />
      )}
      {activeTab === "wallet" && <UserInfoTabWallet />}
      {activeTab === "settings" && <UserInfoTabSettings />}

      {activeTab === "reviews" && <UserInfoTabReviews user={user} />}

      {user?.status === 'banned' && (
        <Box sx={{ color: 'primary.main', fontSize: "24px", textAlign: "center" }}>
          Аккаунт заблокирован
        </Box>
      )}

      <AddProductModal
        open={showAddProduct}
        close={() => setShowAddProduct(false)}
      />
    </Box>
  );
};

export default VendorDashboard;

import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getLatestOrderByUserSlug, getSingleOrderbyID } from "../../../api/order/OrdersApi";
import { useTranslate } from "../../../context/translate/translate.context";
import { isRuCountry } from "../../../lib/country";
import LocalButton from "../../UI/Button";
import ArrowLeft from "../../svg/ArrowLeft";
import { TranslateText } from "../../translate-text";
import OrderProduct from "../OrderProduct";
import styles from "./styles.module.css";

function OrderPageBuyer() {
  const { id } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const userRedux = useSelector((state) => state.user);

  const [isMe, setIsMe] = useState(false);
  const [order, setOrder] = useState(null);

  let totalOrderAmount = 0;
  if (order && order.products) {
    for (const product of order.products) {
      if (product && product.quantity && product.price) {
        totalOrderAmount += product.quantity * product.price;
      }
    }
  }


  const getLatestOrder = async (slug) => {
    try {
      //console.log('executed')
        const res = await getLatestOrderByUserSlug(slug)
        if(res.data){
          const order = res.data
          const price = parseFloat(order.product.price);
          const quantity = 1;
          const discount = order.product.promocode?.discount || 0;
          const totalPrice = price * quantity * (1 - discount / 100);
          const formattedOrder = {
            id: order.product._id,
            name: order.product.name,
            price: parseFloat(totalPrice.toFixed(2)), // Ensure two decimal places
            quantity: parseInt(quantity),
            category: order.product.category?.name,
          }
          if (window.dataLayer) {
            window.dataLayer.push({
              "ecommerce": {
                "currencyCode": "RUB",
                "purchase": {
                  "actionField": {
                    "id": formattedOrder._id
                  },
                  "products": formattedOrder
                }
              }
            });
          }
        }
    } catch(err) {
      console.log(err)
    }
  }

  const fetchData = async (id) => {
    try {
      const res = await getSingleOrderbyID(id, userRedux.country);
      if (res.data.isMe === false) {
        setIsMe(false);
        setOrder(null);
        navigate("/");
      } else {
         // if data.logs.text have entries that repeat themselves, remove them
        if(res.data.logs) {
          const logs = res.data.logs.map((log, index) => {
            if (index > 0 && log.text === res.data.logs[index - 1].text) {
              return null;
            }
            return log;
          }
          ).filter(log => log !== null);
          res.data.logs = logs;
        }
        setOrder(res.data);
        setIsMe(true);
      }
    } catch (error) {
      setIsMe(false);
      setOrder(null);
      navigate("/404");
    }
  };

  useEffect(() => {
    setIsMe(false);
    setOrder(null);
    if (!id) return;
    const token = Cookies.get("refreshToken");
    const user = localStorage.getItem("user");
    const userParsed = JSON.parse(localStorage.getItem("user"))
    const userSlug = userParsed.userSlug
    if (token && user) {
      fetchData(id);
      const params = new URLSearchParams(window.location.search); 
      if(params?.size > 0 && userSlug) {
        const from = params.get('from');
        if(from === 'paidBalance') {
          //console.log(userSlug)
          getLatestOrder(userSlug)
        }
      }
    } else {
      setIsMe(false);
      setOrder(null);
      navigate("/");
    }
  }, [id]);

  const currencySign = () => {
    if (isRuCountry(userRedux.country)) {
      return "₽";
    }
    return "$";
  };

  if (isMe !== true || !order) {
    return;
  }

  const handleGoBack = () => {
    window.history.length > 1
      ? window.history.back()
      : (window.location.href = "/");
  };

  // function to convert timestamp to date
  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <Container className={styles.container}>
      <LocalButton
        color="white"
        startIcon={
          <ArrowLeft
            color={theme.palette.mode === "dark" ? "#F6F6F6" : "#1f1d22"}
          />
        }
        onClick={handleGoBack}
        aria-label={translate("products.productCodes.codesTable.back")}
      >
        <TranslateText value="products.productCodes.codesTable.back" />
      </LocalButton>

      <Box className={styles.frameContainer}>

        <Typography className={styles.frameTitle}>
          <TranslateText value="orders.orderPageBuyer.title" />
        </Typography>


        <Card className={styles.frameCard}>
          <Box
            onClick={() => navigate(`/profile/${order?.seller?.userSlug}`)}
            className={styles.buyerInfo}
          >
            <Avatar
              src={order.seller?.avatar}
              className={styles.buyerAvatar}
            />
            <Typography className={styles.buyerUserName}>
              {order?.seller?.username}
            </Typography>
          </Box>

          <Box className={styles.buyerButtonsContainer}>
            <Button
              variant="outlined"
              className={styles.buyerStartChat}
              onClick={() => navigate(`/chat?u=${order?.seller?._id}`)}
            >
              <TranslateText value="orders.orderPage.buyerInfo.startChatButtonLabel" />
            </Button>
          </Box>
        </Card>
        <Box className={styles.orderContainer}>
          
          <Box className={styles.leftContainer}>
            <Card className={styles.productContainer}>
              {!!order?.productsInfo?.[0]?.product && (
                <OrderProduct
                  order={order}
                  buyer={order?.buyer}
                  product={order?.productsInfo?.[0]}
                  isPurchase={true}
                  currencySign={currencySign()}
                  reset={() => fetchData(id)}
                />
              )}
            </Card>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            minWidth: "349px",
          }}>
            <Card className={styles.transactionContainer}>
              <Typography className={styles.transactionTitle}>
                <TranslateText value="orders.orderPageBuyer.transaction.title" />
              </Typography>

              <Box className={styles.transactionList}>
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.paymentMethod.title" />
                  </Typography>
                  <Typography>
                    <TranslateText
                      value={`orders.orderPageBuyer.transaction.paymentMethod.${
                        order?.transactionBuyer?.paidWith || ""
                      }`}
                    />
                  </Typography>
                </Box>
                {/*
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.createdOn" />
                  </Typography>
                  <Typography>
                    {order.transactionBuyer.createdAt
                      .split("T")[0]
                      .replaceAll("-", ".")}
                  </Typography>
                </Box>
                */}
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.status.title" />
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        order?.transactionBuyer?.status === "succeeded"
                          ? "green"
                          : "red",
                    }}
                  >
                    <TranslateText
                      value={`orders.orderPageBuyer.transaction.status.${order.transactionBuyer.status}`}
                    />
                  </Typography>
                </Box>
                {/*
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.currency" />
                  </Typography>
                  <Typography>{order.transactionBuyer.currency}</Typography>
                </Box>
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.conversionRate" />
                  </Typography>
                  <Typography>{order.transactionBuyer.conversionRate}</Typography>
                </Box>
                */}
                <Box className={styles.transactionListItem}>
                  <Typography>
                    <TranslateText value="orders.orderPageBuyer.transaction.discountCode" />
                  </Typography>
                  <Typography>
                    {order.transactionBuyer.discountCode || "-"}
                  </Typography>
                </Box>

                <Divider />
              </Box>

              <Box className={styles.transactionListItem}>
                <Typography className={styles.totalTitle}>
                  <TranslateText value="orders.orderPageBuyer.transaction.total" />
                </Typography>

                <Typography className={styles.totalValue}>
                  {order?.transactionBuyer?.total
                    ? `${order.transactionBuyer.total.toFixed(2)} ` +
                      currencySign()
                    : null}
                </Typography>
              </Box>

              <Button
                variant="outlined"
                className={styles.viewInWalletButton}
                onClick={() =>
                  navigate(
                    `/profile/${order.buyer.userSlug}#wallet?id=${order.transactionBuyer._id}`
                  )
                }
              >
                <TranslateText value="orders.orderPageBuyer.transaction.viewInWalletButtonLabel" />
              </Button>
            </Card>
          </Box>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '10px',
        justifyContent: 'space-between',
        width: '100%',
        '@media (max-width: 600px)': {
          flexDirection: 'column',
        }
      }}>
      <Box className={styles.eventsContainer}>
        <Typography className={styles.eventsTitle}>
          События:
        </Typography>


        <Box className={styles.eventsList}>
          
          {order?.logs?.reverse().map((log, index) => (
            <Box key={index} className={styles.event}>
              <Typography className={styles.eventText}>
                {(order.logs.length - index) + 1}. {log.text}:
              </Typography>
              <Typography className={styles.eventTime}>
                {convertTimestamp(log.time)}
              </Typography>
            </Box>
          ))}

          <Box className={styles.event}>
            <Typography className={styles.eventText}>
              1. Заказ создан: 
            </Typography>
            <Typography className={styles.eventTime}>
              {convertTimestamp(order?.createdAt)}
            </Typography>       
          </Box>

        </Box>
      </Box>

      <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
        maxWidth: "700px",
      }}>

        <Typography variant="h6">
          ❗️ Инструкция после покупки
        </Typography>

        <List sx={{ listStyleType: 'disc' }}>
          <ListItem sx={{ display: 'list-item' }}>
            Удержание средств: средства удерживаются на стороне площадки для безопасности вашей сделки.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Данные товара: ознакомьтесь с данными, которые продавец указал при публикации.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Чат с продавцом: если данных недостаточно и у вас возникли вопросы, или вам понадобилась дополнительная информация — свяжитесь с продавцом в чате сделки.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Решение проблемы: в случае возникновения проблем попробуйте решить их напрямую с продавцом. Сообщите о проблеме нам, если решить проблему с продавцом не удалось.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Выполнение заказа: у продавца есть 24 часа чтобы предоставить товар.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Проверка товара: проверьте товар после того, как продавец отметит заказ выполненным. Убедитесь, что товар соответствует описанию.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Подтверждение получения: продавец не получит оплату, пока вы не подтвердите получение товара.
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            Завершение сделки: после выполнения заказа сделка подтвердится автоматически поддержкой через 3 дня, если вы не сообщите о проблеме. После подтверждения получения товара сделка считается завершенной и продавцу перечисляется оплата.
          </ListItem>
        </List>

        <Typography variant="h6">
          ❗️ Для безопасности сделки:
        </Typography>

        <List sx={{ listStyleType: 'disc' }}>
          <ListItem>
            ⛔️ Не ведите переписку за пределами Mentup.
          </ListItem>
          <ListItem>
            ⛔️ Не отправляйте денежные средства на прямые реквизиты продавца.
          </ListItem>
          <ListItem>
            ⛔️ Не разглашайте данные, связанные с вашим профилем Mentup (пароль от почты профиля или код для входа в профиль).
          </ListItem>
          <ListItem>
            ⛔️ Не переходите по подозрительным ссылкам.
          </ListItem>
        </List>
        
        <Typography>
          🔰 Служба поддержки Mentup Support  готова помочь вам 24/7 в случае возникновения проблем.
        </Typography>

        <Typography>
          Желаем удачных сделок! 💜
        </Typography>
      </Box>
      </Box>
    </Container>
  );
}

export default OrderPageBuyer;
